<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";

    const brands = [
        {
            name: "paradigma",
            tags: ["branding"],
            href: "https://www.behance.net/paradiginnovation",
            img: [
                'rebranding-project-paradigma-manifesto-agile',
                'rebranding-project-paradigma-t-shirt',
                'rebranding-project-paradigma-gadget',
                'rebranding-project-paradigma-bag'
            ]
        },
        {
            name: "io-comunico",
            tags: ["branding", "webapp-design", "ui-library", "illustration-library"],
            href: "https://www.behance.net/gallery/175173779/-Io-comunico",
            img: [
                'webapp-project-iocomunico',
                'webapp-project-iocomunico-ui-library',
                'webapp-project-iocomunico-illustrations',
                'webapp-project-iocomunico-brand-logo'
            ]
        },
        {
            name: "ledgenda",
            tags: ["branding", "webapp-design", "development"],
            href: "https://www.behance.net/gallery/113515539/-Ledgenda-branding",
            img: [
                'brand-identity-project-ledgenda',
                'brand-identity-project-ledgenda-brand-kit',
                'brand-identity-project-ledgenda-summary',
                'brand-identity-project-ledgenda-typography'
            ]
        },
        {
            name: "nutribees",
            tags: [ "webapp-design", "development"],
            href: "https://www.behance.net/gallery/154696249/Nutribees-dashboard-design",
            img: [
                'webapp-project-nuntribees',
                'webapp-project-nutribees-ui-elements',
                'webapp-project-nutribees-dashboard',
                'webapp-project-nutribees-typography'
            ]
        },
        {
            name: "pokehouse",
            tags: ["webapp-design", "development"],
            href: "https://www.behance.net/gallery/111281957/Poke-House",
            img: [
                'webapp-project-poke-house',
                'mobile-webapp-poke-house',
                'webapp-redesign-project-poke-house',
                'desktop-webapp-poke-house'
            ]
        },
        {
            name: "vesti-il-futuro",
            tags: ["webapp-design", "lead-generation", "gamification"],
            href: "https://www.behance.net/gallery/130624267/-Vesti-il-futuro",
            img: [
                'webapp-project-onlus-manitese',
                'desktop-webapp-vesti-il-futuro',
                'webapp-project-vesti-il-futuro',
                'mobile-webapp-onlus-manitese'
            ]
        },
        {
            name: "gvp",
            tags: ["personal-branding"],
            href: "https://www.behance.net/gallery/166138913/GVP-Personal-Branding",
            img: [
                'personal-branding-GVP',
                'personal-branding-GVP-press-kit',
                'personal-branding-giuseppe-vincenzo-presti',
                'personal-braniding-GVP-headed-paper-business-cards'
            ]
        },
        {
            name: "multicanapa",
            tags: ["branding"],
            href: "https://www.behance.net/gallery/143844677/-multicanapa-branding",
            img: [
                'brand-identity-project-multicanapa',
                'brand-identity-project-multicanapa-cbd-oil-label',
                'brand-identity-project-multicanapa-paper-kit',
                'brand-identity-project-multicanapa-stickers'
            ]
        }
    ];

    const currentBrandIndex = ref<number>(0);
    const isMobile = ref<boolean>(true);

    let animationBlock = true;

    const { locale } = useI18n();
    const globalState = useGlobalState();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    const animationBlockTLProps = {
        onStart: () => {
            animationBlock = true;
        },
        onComplete: () => {
            animationBlock = false;
        },
    };

    //PREV NEXT BRAND
    const prevNextBrand = (direction: "prev" | "next") => {
        if (!animationBlock) {
            const targetIndex = currentBrandIndex.value + (direction === "prev" ? -1 : 1);

            const index =
                direction === "prev"
                    ? targetIndex < 0
                        ? brands.length - 1
                        : targetIndex
                    : targetIndex > brands.length - 1
                    ? 0
                    : targetIndex;

            changeBrandAnimation({
                direction,
                newIndex: index,
            });
        }
    };

    //CHANGE BRAND ANIMATION
    const changeBrandAnimation = ({
        direction,
        newIndex,
    }: {
        direction: "prev" | "next";
        newIndex: number;
    }) => {
        const yValue = 20 * -1;

        gsap.timeline({ ...animationBlockTLProps })
            //MOVE IMAGE COLORED OVERLAY TO BOTTOM
            .to(".pdg-we-do-container .pdg-animated-image-overlay", {
                y: "100%",
                opacity: 1,
                duration: 0,
            })
            //SHOW IMAGE COLORED OVERLAY
            .to(
                ".pdg-we-do-container .pdg-animated-image-overlay",
                {
                    y: 0,
                    duration: 0.3,
                },
                "change"
            )
            //HIDE BRAND INFO
            .to(
                ".pdg-we-do-container .pdg-brand-info.active .pdg-fade-in",
                {
                    opacity: 0,
                    y: yValue,
                    stagger: 0.1,
                    onComplete: () => {
                        currentBrandIndex.value = newIndex;
                    },
                },
                "change"
            )
            //ANIMATED IMAGE SCALE 1.1
            .to(`.pdg-we-do-container .pdg-animated-image`, {
                opacity: 1,
                scale: 1.1,
                duration: 0,
            })
            //SET BRAND INFO BOTTOM
            .to(`.pdg-we-do-container .pdg-brand-info-${newIndex} .pdg-fade-in`, {
                opacity: 0,
                y: -yValue,
                duration: 0,
            })
            //SHOW BRAND INFO
            .to(
                `.pdg-we-do-container .pdg-brand-info-${newIndex} .pdg-fade-in`,
                {
                    opacity: 1,
                    y: 0,
                    stagger: 0.1,
                },
                "end"
            )
            //HIDE IMAGE COLORED OVERLAY
            .to(
                ".pdg-we-do-container .pdg-animated-image-overlay",
                {
                    y: "-100%",
                    duration: 0.3,
                },
                "end"
            )
            //ANIMATED IMAGE SCALE 1
            .to(
                `.pdg-we-do-container .pdg-animated-image`,
                {
                    scale: 1,
                    duration: 0.3,
                },
                "end"
            );
    };

    onMounted(() => {
        if (process.client) {

            const container = document.querySelector(".pdg-we-do-container") as HTMLElement;

            if (container) {
                //GSAP BREAKPOINTS
                const mm = gsap.matchMedia(),
                    breakPoint = 1024;

                mm.add(
                    {
                        isDesktop: `(min-width: ${breakPoint}px)`,
                        isMobile: `(max-width: ${breakPoint - 1}px)`,
                    },
                    ({ conditions }) => {
                        const { isDesktop } = conditions as gsap.Conditions;

                        //IS DESKTOP
                        if (isDesktop) {
                            isMobile.value = false;
                        }

                        //START ANIMATION
                        const startAnimation = gsap
                            .timeline({ ...animationBlockTLProps })
                            //FADE
                            .fromTo(
                                ".pdg-we-do-container .pdg-fade",
                                {
                                    opacity: 0,
                                },
                                {
                                    opacity: 1,
                                },
                                "entry"
                            )
                            //FADE IN
                            .fromTo(
                                [
                                    ".pdg-we-do-container .pdg-title-container .pdg-fade-in",
                                    ".pdg-we-do-container .pdg-description .pdg-fade-in",
                                    ".pdg-we-do-container .active .pdg-fade-in",
                                ],
                                {
                                    opacity: 0,
                                    y: 20,
                                },
                                {
                                    opacity: 1,
                                    y: 0,
                                    stagger: 0.1,
                                },
                                "entry"
                            );

                        //ANIMATION DELAY
                        animationDelay(startAnimation, container);

                        //OPEN IMAGE ANIMATION
                        imageAnimation({
                            tl: startAnimation,
                            container: `.pdg-we-do-container .active`,
                            keyframe: "entry",
                        }).open();

                        //TITLE ANIMATION
                        textAnimation({
                            tl: startAnimation,
                            split: true,
                            container: ".pdg-we-do-container .pdg-title-content",
                            elements: [".pdg-header"],
                            from: { yPercent: 100 },
                            to: { yPercent: 0 },
                            keyframe: "entry",
                        });

                        //CHIP ANIMATION
                        chipAnimation({
                            tl: startAnimation,
                            container: ".pdg-we-do-container",
                            keyframe: "entry",
                        });

                        ScrollTrigger.create({
                        animation: startAnimation,
                        trigger: ".pdg-we-do-container",
                        start: "top 50%",
                        end:"bottom 50%",
                        id: "WEDO",
                        preventOverlaps: true,
                        toggleActions: "play reverse play reverse",
                        // markers: {startColor: "black", endColor: "red", fontSize: '30'},
                        onEnter: () => {
                            setTimeout (() => {
                                gsap.to("body", {
                                    backgroundColor: '#fff',
                                    overwrite: "auto"
                                }),
                                gsap.to( ".content-menu", {
                                    background: '#fff',
                                })
                            }, 0)
                        },
                        onLeaveBack: () => {
                            setTimeout (() => {
                                gsap.to("body", {
                                    backgroundColor: globalState.value.mode === 'normal' ? '#246399' : '#ffc0d5',
                                    overwrite: "auto"
                                })
                                gsap.to( ".content-menu", {
                                    background: globalState.value.mode === 'normal' ? '#246399' : '#ffc0d5',
                                })
                            }, 0)
                        }});
                    }
                );
            }
        }
    });
</script>

<template>
    <section class="pdg-we-do-container pdg-container pdg-container-height">
        <div class="row">
            <!-- TITLE CONTAINER -->
            <div class="pdg-title-container col-md-12">
                <!-- TITLE CONTAINER ROW -->
                <div class="pdg-title-container-row row">
                    <!-- TITLE CONTENT -->
                    <div class="pdg-title-content col-md-6 col-lg-5">
                        <Chip :title="translations?.['we-do'].chip" class="blue" />
                        <h1 class="pdg-title pdg-header">
                            {{ translations?.['we-do']?.title }}
                        </h1>
                    </div>

                    <!-- DESCRIPTION -->
                    <p
                        class="pdg-description pdg-body-2 pdg-remove-br-md col-10 col-md-3 pdg-fade-in"
                        v-html="translations?.['we-do']?.description"
                    />
                </div>
            </div>

            <!-- BRAND CONTAINER -->
            <div class="pdg-brand-container col-md-12">
                <!-- BRAND CONTAINER ROW -->
                <div class="pdg-brand-container-row row">
                    <!-- BRAND IMAGES CONTAINER -->
                    <div class="pdg-brand-images-container col-md-8">
                        <!-- BRAND IMAGES CONTENT (CYCLED) -->
                        <div
                            class="pdg-brand-images-content"
                            :class="currentBrandIndex === index ? 'active' : ''"
                            :aria-hidden="currentBrandIndex === index"
                            v-for="({ name: brandName, img }, index) in brands"
                            :key="index"
                        >
                            <AnimatedImage
                                class="pdg-brand-image"
                                :src="`/assets/img/we-do/${brandName}/${img[0]}.webp`"
                                :alt="translations?.['we-do']?.brand?.[brandName]?.alt?.['1']"
                                sizes="50vw md:50vw"
                            />
                            <AnimatedImage
                                class="pdg-brand-image"
                                :src="`/assets/img/we-do/${brandName}/${img[1]}.webp`"
                                :alt="translations?.['we-do']?.brand?.[brandName]?.['alt']?.['2']"
                                sizes="50vw md:50vw"
                            />
                            <AnimatedImage
                                class="pdg-brand-image"
                                :src="`/assets/img/we-do/${brandName}/${img[2]}.webp`"
                                :alt="translations?.['we-do']?.brand?.[brandName]?.alt?.['3']"
                                sizes="50vw md:50vw"
                            />
                            <AnimatedImage
                                class="pdg-brand-image"
                                :src="`/assets/img/we-do/${brandName}/${img[3]}.webp`"
                                :alt="translations?.['we-do']?.brand?.[brandName]?.alt?.['4']"
                                sizes="50vw md:50vw"
                            />
                        </div>
                    </div>

                    <!-- BRAND INFO CONTAINER -->
                    <div class="pdg-brand-info-container col-md-3 pdg-fade">
                        <!-- SLIDER CONTROLS -->
                        <SliderControls
                            :prevAction="() => prevNextBrand('prev')"
                            :nextAction="() => prevNextBrand('next')"
                            class="dark-blue"
                        />

                        <!-- BRAND INFO (CYCLED)-->
                        <div
                            class="pdg-brand-info"
                            :class="{
                                active: currentBrandIndex === index,
                                ['pdg-brand-info-' + index]: true,
                            }"
                            :aria-hidden="currentBrandIndex === index"
                            v-for="({ name: brandName, tags, href }, index) in brands"
                            :key="index" 
                        >
                            <!-- BRAND INFO TITLE CONTAINER -->
                            <div class="pdg-brand-info-title-container">
                                <!-- BRAND INFO TITLE -->
                                <h2 class="pdg-brand-info-title pdg-subhead-1 pdg-fade-in">
                                    {{ translations?.['we-do']?.brand?.[brandName]?.title }}
                                </h2>

                                <!-- BRAND INFO TAGS -->
                                <div class="pdg-brand-info-tags pdg-label-1 pdg-fade-in">
                                    <span class="pdg-tag" v-for="(tag, index) in tags" :key="index">{{
                                        translations?.['we-do']?.tags?.[tag]
                                    }}</span>
                                </div>
                            </div>

                            <!-- BRAND INFO DESCRIPTION -->
                            <p
                                class="pdg-brand-info-description pdg-body-2 pdg-fade-in"
                                :aria-hidden="isMobile"
                            >
                                {{ translations?.['we-do']?.brand?.[brandName]?.description }}
                            </p>

                            <!-- BRAND LINK -->
                            <ArrowLink
                                :text="translations?.['we-do']?.brand?.[brandName]?.cta"
                                :href="href"
                                :external="true"
                                class="pdg-fade-in"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    //CONTAINER
    .pdg-we-do-container {
        min-height: 100vh;

        color: var(--dark-blue);

        padding-top: rem(114);
        padding-bottom: rem(40);

        @media screen and (min-width: map-get($breakpoints, md)) {
            padding-top: vh(129);
            padding-bottom: vh(114);
        }

        //TITLE CONTAINER
        .pdg-title-container {
            margin-bottom: rem(24);

            @media screen and (min-width: map-get($breakpoints, md)) {
                margin-bottom: rem(100);
            }

            //TABLET
            @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                margin-bottom: rem(44);
            }

            //TITLE CONTAINER ROW
            .pdg-title-container-row {
                //TITLE
                
                .pdg-title {
                    margin: rem(8) 0;

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        margin-bottom: 0;
                    }
                }
            }

            //DESCRIPTION
            .pdg-description {
                margin-bottom: 0;
                
                align-content: end;

                //TABLET
                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: map-get($horizontal-breakpoints, md)){
                    width: 33.33333333%;
                }

                @media screen and (max-width: calc(map-get($breakpoints, md) - 1px)) {
                    font-size: rem(16);
                    line-height: rem(20);
                }
            }
        }

        //BRAND CONTAINER
        .pdg-brand-container {
            //BRAND CONTAINER ROW
            .pdg-brand-container-row {
                //BRAND IMAGES CONTAINER
                .pdg-brand-images-container {
                    margin-bottom: rem(32);

                    //TABLET
                    @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                        width: 100%;
                    }

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        margin-bottom: 0;
                    }

                    //BRAND IMAGES CONTENT (CYCLED)
                    .pdg-brand-images-content {
                        display: grid;

                        grid-template-columns: repeat(2, 1fr);

                        gap: rem(8);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            gap: rem(16);
                        }

                        //BRAND IMAGE
                        .pdg-brand-image {
                            :deep(.pdg-animated-image-overlay) {
                                background: var(--green);
                            }

                            //FIRST IMAGE
                            &:nth-child(1) {
                                grid-row: 1;
                                grid-column: 1;

                                min-height: rem(96);
                                height: vh(96);

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    height: vh(180);
                                }
                            }

                            //SECOND IMAGE
                            &:nth-child(2) {
                                grid-row: 1/3;
                                grid-column: 2;

                                min-height: rem(148);
                                height: vh(148);

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    height: vh(280);
                                }
                            }

                            //THIRD IMAGE
                            &:nth-child(3) {
                                grid-row: 2/4;
                                grid-column: 1;

                                min-height: rem(140);
                                height: vh(140);

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    height: vh(260);
                                }
                            }

                            //FOURTH IMAGE
                            &:nth-child(4) {
                                grid-row: 3;
                                grid-column: 2;

                                min-height: rem(80);
                                height: vh(88);

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    height: vh(160);
                                }
                            }
                        }
                    }
                }

                //BRAND INFO CONTAINER
                .pdg-brand-info-container {
                    @media screen and (min-width: map-get($breakpoints, md)) {
                        padding-left: rem(24);
                    }

                    //TABLET
                    @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) {
                        width: 33.33333333%;

                        @media screen and (min-height: map-get($breakpoints, md)) {
                            width: 100%;

                            padding-left: rem(8);
                        }
                    }

                    //SLIDER CONTROLS
                    .pdg-slider-controls {
                        margin-bottom: rem(16);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            margin-bottom: rem(82);
                        }

                        //TABLET
                        @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                            margin-top: rem(24);
                            margin-bottom: rem(32);
                        }
                    }

                    //BRAND INFO (CYCLED)
                    .pdg-brand-info {
                        @include flex($flex-direction: column);

                        gap: rem(16);

                        //BRAND INFO TITLE CONTAINER
                        .pdg-brand-info-title-container {
                            //BRAND INFO TITLE
                            .pdg-brand-info-title {
                                margin-bottom: rem(8);

                                @media screen and (max-width: calc(map-get($breakpoints, md) - rem(1))) {
                                    font-size: rem(24);
                                }

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    margin-bottom: rem(12);
                                }
                            }

                            //BRAND INFO TAGS
                            .pdg-brand-info-tags {
                                @include flex($flex-wrap: wrap);

                                gap: rem(12);

                                .pdg-tag {
                                    display: inline-block;
                                }
                            }
                        }

                        //BRAND INFO DESCRIPTION
                        .pdg-brand-info-description {
                            display: none;

                            @media screen and (min-width: map-get($breakpoints, md)) {
                                display: initial;

                                margin-bottom: 0;
                            }
                        }
                    }
                }

                /***************
                * GLOBAL STYLE
                ***************/
                .pdg-brand-images-container,
                .pdg-brand-info-container {
                    position: relative;
                }

                .pdg-brand-images-content,
                .pdg-brand-info {
                    &:not(.active) {
                        visibility: hidden;
                        position: absolute;
                        inset: 0;
                    }

                    &.active {
                        position: relative;
                        visibility: visible;
                    }
                }
            }
        }
    }
</style>
