<template>
    <section class="pdg-we-want-container pdg-container-height">
        <div class="pdg-container">
            <div class="pdg-structure-we-want">
                <div class="pdg-title-container">
                    <Chip
                        id="pdg-title-chip"
                        class="pdg-chip-main"
                        icon="asterisk-01"
                        :title="translations?.['we-want']?.title?.['title-chip']"
                        :class="'blue'"
                    />
                    <h1 class="pdg-header pdg-title-we-want">
                        {{ translations?.['we-want']?.title?.main }}
                    </h1>
                </div>

                <div
                    :class="`pdg-default-column-width pdg-image-position-${slideAnimatedImage[index]}`"
                    v-for="(path, index) in (globalState.mode === 'crazy' ? imgPathCrazy : imgPathNormal)"
                    :key="index"
                >
                    <AnimatedImage
                        class="pdg-responsive-image-container pdg-animated-image-padding"
                        :src="`/assets/img/${globalState.mode}/we-want/${path}.webp`"
                        :alt="translations?.['we-want']?.['we-want-card']?.[`card-${index + 1}`]?.[`alt-${index + 1}`]"
                    />
                    <Chip
                        class="pdg-top-chip"
                        icon="asterisk-01"
                        :title="translations?.['we-want']?.['we-want-card']?.[`card-${index + 1}`]?.title"
                        :class="themeChip[index]"
                    />
                </div>

                <div class="pdg-position-description">
                    <p 
                        class="pdg-body-2"
                        v-html="translations?.['we-want']?.description"
                    >
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";
    import { imageAnimation, chipAnimation } from "~/utils/animations.utils";

    const slideAnimatedImage = ["grow", "project", "innovate", "fun"];
    const imgPathNormal = ["presentation-of-company-values", "development", "design", "support-between-teams"];
    const imgPathCrazy = ["easy-solutions-to-tough-problems", "drama-queen-back-from-team-building-on-etna", "life-is-sweet", "slide4"];
    const themeChip = ["pink-static", "dark-pink", "light-blue", "green"];

    const { locale } = useI18n();
    const globalState = useGlobalState();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    onMounted(() => {
        if (process.client) {

            const container = document.querySelector(".pdg-we-want-container") as HTMLDivElement;

            if (container) {
                const infoChipArray = Array.from(
                    container.querySelectorAll(".pdg-chip") as NodeListOf<HTMLSpanElement>
                );
                const chipMain = infoChipArray.find((chip) =>
                    chip.classList.contains("pdg-chip-main")
                );
                const chipsWithoutMain = infoChipArray.filter(
                    (chip) => chip !== chipMain
                );

                const mm = gsap.matchMedia(),
                    breakPoint = 1024;
                mm.add(
                    {
                        isDesktop: `(min-width: ${breakPoint}px)`,
                        isMobile: `(max-width: ${breakPoint - 1}px)`,
                    },
                    () => {
                        const tl = gsap.timeline();

                        //ANIMATION DELAY
                        animationDelay(tl, container);

                        //CHIP ANIMATION
                        chipAnimation({
                            tl,
                            container: ".pdg-we-want-container",
                            target: chipMain,
                            keyframe: "entry +=0",
                        });

                        textAnimation({
                            tl,
                            split: true,
                            container: ".pdg-we-want-container .pdg-title-container",
                            elements: [".pdg-header"],
                            from: { yPercent: 100 },
                            to: { yPercent: 0 },
                            keyframe: "entry +=0.2",
                        });

                        //OPEN IMAGE ANIMATION
                        imageAnimation({
                            tl,
                            container: ".pdg-we-want-container",
                            keyframe: "entry +=0.2",
                        }).open();

                        if (chipsWithoutMain) {
                            chipsWithoutMain.forEach((chip) => {
                                chipAnimation({
                                    tl,
                                    container: ".pdg-we-want-container",
                                    target: chip,
                                    from: { delay: 0.3 },
                                    keyframe: "entry +=0.2",
                                });
                            });
                        }

                        tl.fromTo(
                            ".pdg-we-want-container .pdg-position-description .pdg-body-2",
                            {
                                yPercent: 100,
                                opacity: 0,
                            },
                            {
                                yPercent: 0,
                                opacity: 1,
                                delay: 0.2,
                            },
                            "entry +=0.3"
                        );

                            ScrollTrigger.create({
                                animation: tl,
                                trigger: '.pdg-we-want-container',
                                start: "top 50%",
                                end:"bottom 50%",
                                id: "WEWANT",
                                toggleActions: "play reverse play reverse",
                                preventOverlaps: true,
                                // markers: {startColor: "green", endColor: "green", fontSize: '80'},
                                onEnter: () =>{
                                    setTimeout (() => {
                                        gsap.to("body", {
                                            backgroundColor:  globalState.value.mode === 'normal' ? '#0a2843' : '#c3f73a',
                                            overwrite: "auto"
                                        })
                                        gsap.to( ".content-menu", {
                                            background: globalState.value.mode === 'normal' ? '#0a2843' : '#c3f73a',
                                        })
                                    }, 0)
                                },
                                onEnterBack: () => {
                                    setTimeout (() => {
                                        gsap.to("body", {
                                                backgroundColor:  globalState.value.mode === 'normal' ? '#0a2843' : '#c3f73a',
                                                overwrite: "auto"
                                        })
                                        gsap.to( ".content-menu", {
                                            background: globalState.value.mode === 'normal' ? '#0a2843' : '#c3f73a',
                                        })
                                    }, 0)
                                },
                                onLeaveBack: () =>{
                                    setTimeout (() => {
                                        gsap.to("body", {
                                            backgroundColor: '#fff',
                                            overwrite: "auto"
                                        })
                                        gsap.to( ".content-menu", {
                                            background: '#fff'
                                        })
                                    }, 0)
                                },
                            });
                    }
                );
            }
        }
    });
</script>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .pdg-we-want-container {
        min-height: 100vh;
        color: var(--body-0);
        padding-top: rem(112);
        padding-bottom: rem(100);

        .pdg-chip-main {
            margin-bottom: rem(8);

            @media screen and (min-width: map-get($breakpoints, md)) {
                margin-bottom: rem(16);
            }
        }

        .pdg-top-chip {
            align-self: start;
        }

        .pdg-default-column-width {
            display: flex;
            flex-direction: column;
            margin-bottom: rem(56);
            width: 83.33333333%;

            .pdg-responsive-image-container {
                @media screen and (min-width: map-get($breakpoints, md)) {
                    flex: 1;
                }
            }
        }

        .pdg-structure-we-want {
            @media screen and (min-width: map-get($breakpoints, md)) {
                display: grid;
                grid-template-columns: repeat(12, calc((100% - (rem(16) * 11)) / 12));
                grid-template-rows: repeat(8, calc(((100vh - (rem(16) * 7)) / 8)));
                gap: rem(16);
                padding-bottom: rem(52);
            }

            @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                grid-template-columns: repeat(8, calc((100% - (rem(16) * 7)) / 8));
                grid-template-rows: repeat(12, calc(((100vh - (rem(16) * 11)) / 12)));
            }

            .pdg-animated-image-container {
                margin-bottom: rem(16);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    margin-bottom: vw(16, 1440);
                }

                &:deep(.pdg-animated-image-overlay) {
                    background: var(--bg-1);
                }
            }

            .pdg-title-container {
                text-transform: uppercase;
                margin-bottom: rem(24);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    margin-bottom: 0;
                    grid-column: 1 / 8;
                    padding-right: rem(124);
                }

                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                    margin-bottom: 0;
                    grid-column: 1 / 9;
                    grid-row: 2 / 4;
                }

                .pdg-title-we-want {
                    margin-bottom: 0;
                }
            }

            .pdg-image-position-grow {
                min-height: rem(284);

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    width: 58.33333333%;
                    min-height: rem(404);
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    grid-column: 1 / 4;
                    grid-row: 5 / -1;
                    padding: rem(40) rem(20) rem(16) 0;
                    margin-bottom: 0;
                    width: initial;
                    min-height: rem(288);
                }

                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                    grid-column: 1 / 4;
                    grid-row: 4 / 7;

                    padding: 0;
                }

                @media screen and (min-width: map-get($breakpoints, lg)) {
                    min-height: rem(340);
                }

                @media screen and (min-width: map-get($breakpoints, xl)) {
                    min-height: rem(412);
                }

                &:deep(.pdg-animated-image-overlay) {
                    background: var(--dark-pink);
                }
            }

            .pdg-image-position-project {
                margin-left: 33.33333333%;
                width: 66.66666667%;
                min-height: rem(320);

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    margin-left: 50%;
                    width: 50%;
                    min-height: rem(480);
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    grid-column: 7 / 10;
                    grid-row: 6 / -1;
                    padding: rem(40) rem(24) rem(0) rem(64);
                    width: initial;
                    margin-left: 0;
                    margin-bottom: 0;
                    min-height: rem(288);
                }

                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                    grid-column: 6 / 9;
                    grid-row: 7 / 10;

                    padding: 0;
                }

                @media screen and (min-width: map-get($breakpoints, lg)) {
                    min-height: rem(352);
                }

                @media screen and (min-width: map-get($breakpoints, xl)) {
                    min-height: rem(428);
                }

                &:deep(.pdg-animated-image-overlay) {
                    background: var(--bg-5);
                }
            }

            .pdg-image-position-innovate {
                width: 100%;
                min-height: rem(280);

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    width: 83.33333333%;
                    min-height: rem(472);
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    grid-column: 8 / 12;
                    grid-row: 1 / 5;
                    padding-right: rem(64);
                    padding-top: 0;
                    margin-bottom: 0;
                    width: initial;
                    min-height: rem(288);
                }

                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                    grid-column: 1 / 5;
                    grid-row: 8 / 11;
                }

                @media screen and (min-width: map-get($breakpoints, lg)) {
                    min-height: rem(396);
                }

                @media screen and (min-width: map-get($breakpoints, xl)) {
                    min-height: rem(468);
                }

                &:deep(.pdg-animated-image-overlay) {
                    background: var(--pink);
                }
            }

            .pdg-image-position-fun {
                margin-left: 16.66666667%;
                min-height: rem(296);

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    margin-left: 41.66666667%;
                    width: 58.33333333%;
                    min-height: rem(416);
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    grid-column: 4 / 7;
                    grid-row: 3 / 6;
                    padding-left: rem(20);
                    margin-left: 0;
                    margin-bottom: 0;
                    width: initial;
                    min-height: rem(288);
                }

                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                    grid-column: 5 / 8;
                    grid-row: 4 / 7;

                    margin-top: rem(-32);
                    margin-bottom: rem(32);
                }

                @media screen and (min-width: map-get($breakpoints, lg)) {
                    min-height: rem(280);
                }

                @media screen and (min-width: map-get($breakpoints, xl)) {
                    min-height: rem(332);
                }

                &:deep(.pdg-animated-image-overlay) {
                    background: var(--light-blue);
                }
            }

            .pdg-position-description {
                width: 83.33333333%;

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    width: 58.33333333%;
                }

                .pdg-body-2 {
                    margin-bottom: 0;
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    grid-column: 10 / 13;
                    grid-row: 7 / 9;
                    padding-right: rem(40);
                    width: initial;
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: vw(46, 1440);
                }

                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                    grid-column: 5 / 9;
                    grid-row: 11 / 12;

                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
</style>