<script setup lang="ts">

    defineProps(["prevAction", "nextAction"]);
    const { locale } = useI18n();
    const globalState = useGlobalState();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })
</script>

<template>
    <div class="pdg-slider-controls">
        <button
            class="pdg-slider-control-btn"
            :aria-label="translations?.['slider-controls']?.['aria-left-arrow']"
            @click="prevAction"
        >
            <i class="icon-arrow-right icon" />
        </button>
        <button
            class="pdg-slider-control-btn"
            :aria-label="translations?.['slider-controls']?.['aria-right-arrow']"
            @click="nextAction"
        >
            <i class="icon-arrow-left icon" />
        </button>
    </div>
</template>

<style scoped lang="scss">
@import "../scss/includes.scss";

.pdg-slider-controls {
    @include flex(flex-start, center);

    gap: rem(8);

    //THEME
    &.pink {
        .pdg-slider-control-btn {
            color: var(--body-2);

            border-color: var(--body-2);

            &:hover,
            &:focus {
                color: var(--body-1);
                background: var(--body-2);
            }
        }
    }

    &.dark-blue {
        .pdg-slider-control-btn {
            color: var(--dark);

            border-color: var(--dark);

            &:hover,
            &:focus {
                color: var(--white);
                background: var(--dark-pink);

                border-color: var(--dark-pink);
            }
        }
    }

    //BUTTON
    .pdg-slider-control-btn {
        @include flex(center, center);

        background: none;

        padding: rem(2) rem(10);

        @include typography(28, 400, 28);

        border: 1px solid;
        border-radius: rem(100);

        transition: all 0.3s ease-in-out;

        @media screen and (min-width: map-get($breakpoints, lg)) {
            padding: vw(2, 1440) vw(10, 1440);

            font-size: vw(28, 1440);
            line-height: vw(28, 1440);
        }
    }
}
</style>
