<script setup lang="ts">
    import { gsap } from "gsap";
    import { ScrollToPlugin } from "gsap/all";

    const sidebar = ref<HTMLDivElement | null>(null);
    const currentSection = ref(0);
    const initialSection = ref<boolean>();
    const currentColor = ref("light");
    const colorSections = ref<NodeListOf<HTMLElement> | []>([]);
    const sections = ref<NodeListOf<HTMLElement> | []>([]);
    const nextSection = ref<boolean>();
    let scrollBlock = false;
    const globalState = useGlobalState();

    const hidden = ref<boolean>(false);
    const hiddenClasses: string[] = ['footer'];

    const { locale } = useI18n();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    onMounted(() => {
        if(process.client) {
            nextTick(() => {
                sidebar.value = document.getElementById('container-sidebar') as HTMLDivElement;

                gsap.registerPlugin(ScrollToPlugin);
                if (document.querySelectorAll('.sidebar-section')) {
                    sections.value = document.querySelectorAll('.sidebar-section') as NodeListOf<HTMLElement>;
                    colorSections.value = document.querySelectorAll('[data-sidebar-color]') as NodeListOf<HTMLElement>;
                    if (sections.value.length) {
                        onScroll();
                        window.addEventListener("scroll", onScroll);
                    }

                }
            })
        }
    });

    onUnmounted(() =>{
        window.removeEventListener("scroll", onScroll);
    })

    const onScroll = () =>{
        const windowY = window.scrollY;

        const isOnHeader = windowY < window.innerHeight / 2;

        hidden.value = isOnHeader;

        sections.value?.forEach(({ offsetTop, offsetHeight, className }, index) => {
            const topValue = offsetTop - window.innerHeight / 2;

            if (windowY > topValue && windowY < topValue + offsetHeight) {
                currentSection.value = index;
                initialSection.value = currentSection.value === 0;
                nextSection.value =
                    currentSection.value >= sections.value.length - 1;
                colorSections.value.forEach(
                    ( section, i ) => {
                        
                        const positionSection =  section.getBoundingClientRect();
                        const startChangeColor =  window.innerHeight / 2 ;
                        const sidebarHeight = (sidebar.value?.getBoundingClientRect().height || 0) / 2 ;
                        const checkColor = (positionSection.top - sidebarHeight) <= startChangeColor && (positionSection.bottom - sidebarHeight ) >= startChangeColor;

                        if ( checkColor ) {
                            currentColor.value = section.dataset.sidebarColor || "light";

                            //HIDE SECTION
                            hidden.value = 
                                isOnHeader || hiddenClasses.some((hiddenClass => 
                                    section.className.includes(hiddenClass)
                                ));
                        }
                    }
                );
            }
        });
    }

    const scrollToSection = (index: number) => {
        currentSection.value = index;

        gsap.to(window, {
            scrollTo: sections.value[index].offsetTop,
            duration: 1,
            onUpdate: () => onScroll(),
        });
    };
</script>

<template>
    <Transition>
        <aside class="pdg-sidebar" v-if="!hidden">
            <ul class="pdg-list" :class="`pdg-sidebar-${currentColor}`" id="container-sidebar">
                <li
                    class="pdg-list-item"
                    :class="{ disable: initialSection }"
                    :aria-hidden="initialSection"
                >
                    <button
                        class="pdg-button-icon-arrow"
                        type="button"
                        :disable="initialSection"
                        @click="currentSection > 0 && scrollToSection(currentSection - 1)"
                        :aria-label="translations?.sidebar?.['aria-up-arrow']"
                    >
                        <i class="icon-arrow-up" />
                    </button>
                </li>
                <li class="pdg-list-item" v-for="({ id }, index) in sections" :key="id">
                    <button
                        class="pdg-button-sidebar pdg-list-button"
                        :aria-label="translations?.sidebar?.[id]"
                        type="button"
                        :class="{ active: currentSection === index }"
                        :data-text="translations?.sidebar?.[id]"
                        @click="scrollToSection(index)"
                    />
                </li>
                <li
                    class="pdg-list-item"
                    :class="{ disable: nextSection }"
                    :aria-hidden="nextSection"
                >
                    <button
                        class="pdg-button-icon-arrow"
                        type="button"
                        :disable="nextSection"
                        @click="currentSection < sections.length - 1 && scrollToSection(currentSection + 1)"
                        :aria-label="translations?.sidebar?.['aria-down-arrow']"
                    >
                        <i class="icon-arrow-down" />
                    </button>
                </li>
            </ul>
        </aside>
    </Transition>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .pdg-sidebar {
        position: fixed;
        right: rem(80);
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;

        @media screen and (max-width: calc(map-get($horizontal-breakpoints, md) - rem(0.1))) {
            display: none;
        }

        .pdg-list {
            list-style-type: none;
            padding-left: 0;
            text-align: right;

            .pdg-list-item {
                @include flex($justify-content: flex-end);
                margin-bottom: rem(32);
                transition: $transition;

                &:first-child,
                &:nth-last-child(-n+2) {
                    margin-bottom: rem(24); //TODO: valutare con Nora se metterlo a 32 o lasciarlo a 24 come da layout
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .pdg-button-icon-arrow {
                    display: inline-flex; // lo usiamo per averlo quadrato
                    padding: rem(6);
                    margin: rem(-6);
                    position: relative;
                    @include typography(20, 400, 20);

                    &::before {
                        border-radius: 100%;
                        content: "";
                        display: block;
                        position: absolute;
                        transform: scale(0);
                        inset: 0;
                        transition: transform 0.3s ease-in-out;
                        z-index: -1;
                    }

                    &:focus,
                    &:hover {
                        &::before {
                            transform: scale(1);
                        }
                    }
                }

                .pdg-list-button {
                    padding: rem(16) 0;
                    margin: rem(-16) 0;
                    position: relative;
                    text-transform: uppercase;
                    transition: 0.2s;

                    &::before {
                        background-color: currentColor;
                        bottom: rem(14);
                        border-bottom: rem(1) solid currentColor;
                        content: "";
                        display: block;
                        height: rem(1);
                        position: absolute;
                        right: 0;
                        transition: 0.4s;
                        width: rem(20);
                    }

                    &:after {
                        content: attr(data-text);
                        transition: $transition;
                        opacity: 0;
                        white-space: nowrap;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        &::before {
                            width: 100%;
                            height: rem(2);
                        }

                        &:after {
                            opacity: 1;
                        }
                    }
                }

                .pdg-button-icon-arrow,
                .pdg-list-button {
                    background: transparent;
                    border: none;
                    outline: none;
                }
            }

            &.pdg-sidebar-light {
                .pdg-button-icon-arrow,
                .pdg-list-button {
                    color: var(--light);
                }

                // lo inseriamo in entrambe le classi che passiamo dinamicamente perchè altrimenti non cambia
                .pdg-button-icon-arrow::before {
                    background: color-mix(in srgb, currentColor 20%, transparent);
                }
            }

            &.pdg-sidebar-dark {
                .pdg-button-icon-arrow,
                .pdg-list-button {
                    color: var(--dark);
                }

                .pdg-button-icon-arrow::before {
                    background: color-mix(in srgb, currentColor 20%, transparent);
                }
            }

            .disable {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
</style>
