<template>
  <section class="pdg-container-skills-technologies">
    <div class="pdg-container">
        <Chip
          :title="translations?.['skills-technologies']?.chip"
          class="pink"
        />

        <div class="pdg-title-description">
          <div class="row">
            <h1 class="pdg-title pdg-title-h1 col-10 col-md-12">
              {{ translations?.['skills-technologies']?.title }}
            </h1>
            <p class="pdg-description pdg-body-2">
              {{ translations?.['skills-technologies']?.description }}
            </p>
        </div>
      </div>

      <div class="row">
        <div class="pdg-work-with-us col-md-7">
          <ul 
            class="nav pdg-nav-pills"
            id="pills-tab" 
            role="tablist">
            <li class="pdg-nav-item" role="presentation">
              <button 
                class="pdg-nav-link pdg-label-2 active" 
                id="pills-skills-tab" 
                data-bs-toggle="pill"
                data-bs-target="#pills-skills" 
                type="button" 
                role="tab" 
                :aria-selected="isActive"
                 @click="toggleIsActive">
                {{ translations?.['skills-technologies']?.button?.firstButton }}
              </button>
            </li>
            <li class="pdg-nav-item" role="presentation">
              <button 
                class="pdg-nav-link pdg-label-2" 
                id="pills-technologies-tab" 
                data-bs-toggle="pill"
                data-bs-target="#pills-technologies" 
                type="button" 
                role="tab" 
                :aria-selected="isActive"
                @click="toggleIsActive">
                {{ translations?.['skills-technologies']?.button?.secondButton }}
              </button>
            </li>
          </ul>
          <div class="pdg-tab-content" id="pills-tabContent">
            <div 
              class="pdg-tab-pane active" 
              id="pills-skills" 
              role="tabpanel" 
              aria-labelledby="pills-skills-tab">
              <ul class="pdg-menu-items">
                <li class="pdg-menu-item" v-for="(skill, index) in skills" :key="index">
                  <NuxtImg 
                    :src="`/assets/img/${globalState.mode}/skills-technologies/skills/${skill}.svg`"
                    :alt="translations?.['skills-technologies']?.['alt-skills-menu']?.['skills']?.[index + 1]" 
                    class="pdg-img" 
                    loading="lazy"
                    sizes="160px"
                  />
                    <p class="pdg-subhead-1 pdg-item-margin">
                      {{ translations?.['skills-technologies']?.['service-menu']?.[index+1] }}
                    </p>
                </li>
              </ul>
            </div>
            <div 
              class="pdg-tab-pane" 
              id="pills-technologies" 
              role="tabpanel" 
              aria-labelledby="pills-technologies-tab">
              <ul class="pdg-menu-items">
                <li class="pdg-menu-item" v-for="(tech, index) in technologies" :key="index">
                  <NuxtImg
                    :src="`/assets/img/${globalState.mode}/skills-technologies/technologies/${tech}.svg`"
                    :alt="translations?.['skills-technologies']?.['alt-skills-menu']?.technologies?.[index + 1]" 
                    class="pdg-img"
                    loading="lazy"
                    sizes="160px"
                  />
                  <p class="pdg-subhead-1 pdg-item-margin">
                    {{ translations?.['skills-technologies']?.['technologies-menu']?.[index + 1] }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="pdg-box-info-container col-10 col-md-3">
          <div class="pdg-box-info-padding">
            <h2 class="pdg-title-h4 pdg-title-box-info">
              {{ translations?.['skills-technologies']?.['box-info']?.title }}
            </h2>
            <p class="pdg-description-box-info pdg-body-2" v-html="translations?.['skills-technologies']?.['box-info']?.description">
              
            </p>
            <ArrowLink
              :text="translations?.['skills-technologies']?.['box-info']?.link "
              href="https://applyfor.paradigma.me/"
              :external="true"
              class="pdg-contact-link"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import "../scss/includes.scss";

.pdg-container-skills-technologies {
  /* min-height: 100vh; */
  color: var(--dark-blue);

  @media screen and (min-width: map-get($breakpoints, md)) {
    @include flex($align-items: center);
  }

  .pdg-container {
    padding-top: rem(40);
    padding-bottom: rem(36);

    @media screen and (min-width: map-get($breakpoints, md)) {
      padding-top: rem(24);
      padding-bottom: rem(24);
    }

    .pdg-chip {
      margin-bottom: rem(8);
    }

    .pdg-title-description {
      .pdg-title {
        margin-bottom: rem(16);
      }

      .pdg-description {
        margin-bottom: rem(8);

        @media screen and (min-width: map-get($breakpoints, md)) {
          margin-bottom: rem(16);
        }
      }
    }

    .pdg-work-with-us {
      padding-bottom: rem(40);

      @media screen and (min-width: map-get($breakpoints, md)) {
        padding-bottom: 0;
      }

      .pdg-nav-pills {
        list-style: none;
        padding-bottom: rem(8);
        padding-top: rem(8);

        @media screen and (min-width: map-get($breakpoints, md)) {
          margin-bottom: rem(16);
          position: relative;
          z-index: 3;
        }

        .pdg-nav-item {
          margin-right: rem(12);

          &:last-child {
            margin-right: 0;
          }
        }

        .pdg-nav-link {
          background: var(--bg-0);
          color: var(--body-11);
          padding: rem(4) rem(8);
          border: rem(1) solid var(--body-11);
          border-radius: rem(100);
          transition: $transition;

          @media screen and (min-width: map-get($breakpoints, md)) {
            padding: rem(12);
          }

          &:not(.active) {
            &:focus-visible
            &:focus,
            &:hover {
              background-color: var(--body-5);
              color: var(--body-10);
              border-color: var(--body-5);
            }
          }

          &.active {
            background-color: var(--body-9);
            border-color: var(--body-9);
            color: var(--body-0);
          }
        }
      }

      .pdg-tab-content {
        overflow: visible;

        .pdg-tab-pane {
          display: none;

          &.active {
            display: block;
          }
        }
      }

      .pdg-menu-items {
        list-style: none;
        padding: 0;
        margin: 0;

        .pdg-menu-item {
          display: block;
          padding: rem(8) 0;
          text-transform: uppercase;
          position: relative;
          z-index: 2;

          &:last-child {
            &::after {
              content: none;
            }
          }

          &::after {
            content: '';
            display: block;
            height: rem(1);
            width: 100%;
            left: 0;
            bottom: 0;
            position: absolute;
            background-color: var(--light-blue);
          }

          .pdg-img {
            position: absolute;
            width: rem(200);
            height: rem(200);
            object-fit: cover;
            transform: translateY(-50%);
            top: 50%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            z-index: -1;
            pointer-events: none;

            &:hover {
              color: var(--body-12);
              z-index: 1;
            }
          }

          
        }

        .pdg-item-margin {
          margin-bottom: 0;
          font-weight: 400;
        }
      }
    }

    .pdg-box-info-container {
      //TABLET
      @media screen and (min-width: map-get($breakpoints, md)) and (max-width: map-get($horizontal-breakpoints, md)){
        width: 33.33333333%;
      }

      @media screen and (min-width: map-get($breakpoints, md)) {
        padding-top: rem(8);
      }

      .pdg-box-info-padding {
        @media screen and (min-width: map-get($breakpoints, md)) {
          padding-left: rem(32);
        }

        .pdg-title-box-info {
          margin-bottom: rem(16);
        }

        .pdg-description-box-info {
          margin-bottom: rem(28);
        }
      }
    }
  }
}
</style>

<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Power1 } from "gsap/all";

const isActive = ref(true);
const globalState = useGlobalState();


const technologies = [
  'react-nextjs',
  'typescript',
  'vue',
  'angular',
  'nosql',
  'node-js',
  'flutter',
  'react-native'
]

const skills = [
  'web-mobile-development',
  'cloud-architecture',
  'user-experience-user-design',
  'service-design',
  'visual-graphic-design',
  'motion-graphic',
  'agile-project-management',
  'quality-assurance'
]

const toggleIsActive = () => {
  isActive.value = !isActive.value;
};

  const { locale } = useI18n();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

onMounted(() => {

  if (process.client) {
    
    var tl = gsap.timeline();

    const container: HTMLDivElement | null = document.querySelector(".pdg-container-skills-technologies");

    if (container) {
        const tabsBtn = container.querySelectorAll("[data-bs-toggle]");
        const tabs = Array.from(container.querySelectorAll('.pdg-container-skills-technologies [role="tabpanel"]'));

        const mm = gsap.matchMedia(), breakPoint = 1024;
        mm.add({
          isDesktop: `(min-width: ${breakPoint}px)`,
          isMobile: `(max-width: ${breakPoint - 1}px)`,
        }, ({ conditions }) => {
          const { isDesktop } = conditions as gsap.Conditions;
          (container as HTMLDivElement).style.paddingTop = '0px';
          navbarHeight(container);

          if (tabsBtn) {
            tabsBtn.forEach((tab) => {
              tab.addEventListener("click", (e: Event) => {
                const currentActiveTab = tabs.find((el) => (el as HTMLElement).classList.contains("active"));

                if (!tl.isActive() && !tab.classList.contains("active")) {
                  tabsBtn.forEach((element) => element.classList.remove("active"));
                  tab.classList.add("active");

                  if (e && e.target) {
                    const target = (e.target as HTMLElement).getAttribute("data-bs-target");
                    const activeTab = tabs.find((el) => el.id === (target?.replace("#", "") || ''));

                    tl.to(
                      gsap.utils.toArray(".pdg-container-skills-technologies .pdg-menu-item", currentActiveTab as HTMLObjectElement),
                      {
                        xPercent: -10,
                        duration: 0.3,
                        stagger: 0.1,
                        opacity: 0,
                        onComplete: () => {
                          tabs.forEach((element) => {
                            if (element instanceof HTMLElement) {
                              element.classList.remove("active");
                            }
                          });
                          activeTab?.classList.add("active");
                        },
                      }
                    ).fromTo(
                      gsap.utils.toArray(".pdg-container-skills-technologies .pdg-menu-item", activeTab),
                      {
                        xPercent: 10,
                        opacity: 0
                      },
                      {
                        xPercent: 0,
                        opacity: 1,
                        stagger: 0.1,
                        duration: 0.3
                      }
                    );
                  }
                }
              });
            });
          }

          const startAnimation = gsap.timeline();

          //ANIMATION DELAY
          animationDelay(startAnimation, container);

          //CHIP ANIMATION
          chipAnimation({
            tl: startAnimation,
            container: ".pdg-container-skills-technologies",
            keyframe: "0"
          })

          startAnimation
          .fromTo(
              ".pdg-container-skills-technologies .pdg-nav-item",
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 0.3,
              ease: Power1.easeIn,
            },
            "entry"
          )

          textAnimation({
            split: true,
            tl: startAnimation,
            container: ".pdg-container-skills-technologies .pdg-container",
            elements: [".pdg-title", ".pdg-description, .pdg-title-h4, .pdg-description-box-info"],
            from: { yPercent: 100, opacity: 0 },
            to: { yPercent: 0, opacity: 1 },
            keyframe: 'entry'
          });

          if (isDesktop) {
            const items: NodeListOf<HTMLElement> = container.querySelectorAll(".pdg-menu-item");
            
            startAnimation
              .fromTo(
                [".pdg-container-skills-technologies .pdg-menu-item"],
                {
                  xPercent: 10,
                  opacity: 0
                },
                {
                  xPercent: 0,
                  opacity: 1,
                  stagger: 0.1,
                  duration: 0.3
                },
                'entry'
              )
              .fromTo (
                  [
                    ".pdg-container-skills-technologies .pdg-title-box-info", 
                    ".pdg-container-skills-technologies .pdg-description-box-info",
                    ".pdg-container-skills-technologies .pdg-nav-pills",
                    ".pdg-container-skills-technologies .pdg-contact-link"
                  ], 
                  {
                    y: 50,
                    opacity: 0
                  },
                  {
                    y: 0,
                    opacity: 1,
                    duration: 0.3,

                  onComplete: function () {
                    items && animationImageHover(items);

                    gsap.set(this.targets(), { clearProps: "all" })
                  },
                },
                "entry")
            }
            else {
              startAnimation
                .fromTo(
                  ".pdg-container-skills-technologies .pdg-menu-item",
                  { yPercent: -20, opacity: 0},
                  { yPercent: 0, stagger: 0.1, opacity: 1 },
                  'entry'
                )
                .fromTo (
                  [
                    ".pdg-container-skills-technologies .pdg-title-box-info", 
                    ".pdg-container-skills-technologies .pdg-description-box-info",
                    ".pdg-container-skills-technologies .pdg-contact-link"
                  ], 
                  {
                    y: -50,
                    opacity: 0
                  },
                  {
                    y: 0,
                    opacity: 1,
                    duration: 0.3,
                    stagger: 0.2,

                    onComplete: function () {
                      gsap.set(this.targets(), { clearProps: "all" })
                    },
                  },
                  "entry")
              }

              ScrollTrigger.create({
                animation: startAnimation,
                trigger: container,
                start: "top 50%",
                end:"bottom 50%",
                id: "SKILLS&TECHNOLOGIES",
                preventOverlaps: true,
                toggleActions: "play reverse play reverse",
                //  markers: {startColor: "black", endColor: "red", fontSize: '30'},
                  onEnter: () => {
                    setTimeout (() => {
                      gsap.to( ".content-menu", {
                              background: globalState.value.mode === 'normal' ? '#fff' : '#ffc0d5',
                      })
                      gsap.to("body", {
                        backgroundColor:  globalState.value.mode === 'normal' ? '#fff' : '#ffc0d5',
                        overwrite: "auto"
                    })
                    }, 0)
                 
                },
                  onEnterBack: () => {
                    setTimeout (() => {
                      gsap.to( ".content-menu", {
                              background: globalState.value.mode === 'normal' ? '#fff' : '#ffc0d5',
                      })
                      gsap.to("body", {
                        backgroundColor:  globalState.value.mode === 'normal' ? '#fff' : '#ffc0d5',
                        overwrite: "auto",
                      })
                    }, 0)
                  }
              });
        });
     }
  }
});
</script>