<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";

    const globalState = useGlobalState();

    const adjectives =
    globalState.value.mode === "normal"
            ? ["stubborn", "curious", "creative", "geeks", "imperfect"]
            : ["stubborn", "curious", /* "creative", */ "geeks", "imperfect", "united"];

    const currentAdjectiveIndex = ref<number>(0);

    let adjectiveTimeout: NodeJS.Timeout;

    const { locale } = useI18n();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    //SET ADJECTIVE
    const setAdjective = (adjectiveIndex: number | null) => {
        if (adjectiveIndex !== currentAdjectiveIndex.value) {
            clearTimeout(adjectiveTimeout);

            currentAdjectiveIndex.value = adjectiveIndex || 0;
            adjectiveTimeout = setTimeout(() => {
                closeAll({
                    onComplete: () => {
                        openAnimation();
                    },
                });
            }, 0);
        }
    };

    //ADJECTIVES CONTROLS
    let adjectivesContainer: HTMLElement;

    const prevNextAdjective = (direction: "prev" | "next") => {
        const targetIndex = currentAdjectiveIndex.value + (direction === "prev" ? -1 : 1);

        const index =
            direction === "prev"
                ? targetIndex < 0
                    ? adjectives.length - 1
                    : targetIndex
                : targetIndex > adjectives.length - 1
                ? 0
                : targetIndex;

        if (adjectives[index]) {
            adjectivesContainer &&
                adjectivesContainer.scrollTo({
                    left:
                        (
                            adjectivesContainer.querySelector(
                                `.pdg-adjective-${index}`
                            ) as HTMLElement
                        )?.offsetLeft - 16,
                    behavior: "smooth",
                });

            setAdjective(index);
        }
    };

    //OPEN SLIDE ANIMATION
    const openAnimation = () => {
        const openAnimation = gsap
            .timeline()
            //ADJECTIVE SLIDE
            .to(`.pdg-we-are-container .pdg-slide-${currentAdjectiveIndex.value + 1}`, {
                display: "flex",
                duration: 0,
            });

        //OPEN IMAGE ANIMATION
        imageAnimation({
            tl: openAnimation,
            container: `.pdg-we-are-container .pdg-slide-${currentAdjectiveIndex.value + 1}`,
        }).open();
    };

    //CLOSE SLIDE ANIMATION
    const closeAll = (props: gsap.TimelineVars) => {
        const closeAnimation = gsap.timeline(props);

        //OPEN IMAGE ANIMATION
        imageAnimation({
            tl: closeAnimation,
            container: `.pdg-we-are-container`,
        }).close();

        //ADJECTIVE SLIDE
        closeAnimation.to(
            `.pdg-we-are-container .pdg-we-are-adjectives-slides-container .pdg-we-are-adjectives-slide`,
            {
                display: "none",
                duration: 0,
            }
        );
    };

    onMounted(() => {

        if (process.client) {
            const mm = gsap.matchMedia(),
                breakPoint = 1024;

            mm.add(
                {
                    isDesktop: `(min-width: ${breakPoint}px)`,
                    isMobile: `(max-width: ${breakPoint - 1}px)`,
                },
                () => {
                    //CONTAINER
                    const container = document.querySelector(".pdg-we-are-container") as HTMLElement;
                    //SET ADJECTIVE CONTAINER VARIABLE
                    adjectivesContainer = container.querySelector(
                        ".pdg-we-are-adjectives"
                    ) as HTMLElement;

                    //START ANIMATION
                    const startAnimation = gsap
                        .timeline({ onStart: () => {setAdjective(null)} })
                        //ADJECTIVES
                        .fromTo(
                            ".pdg-we-are-container .pdg-we-are-adjectives-container .pdg-we-are-adjectives .pdg-we-are-adjective",
                            {
                                visibility: "hidden",
                                y: 32,
                                opacity: 0,
                            },
                            {
                                visibility: "visible",
                                y: 0,
                                margin: 0,
                                opacity: 1,
                                stagger: 0.1,
                                onComplete: () => ScrollTrigger.refresh(),
                            },
                            "entry"
                        )
                        const sliderControls = container.querySelector('.pdg-slider-controls')
                        if(sliderControls) {
                            //ADJECTIVES CONTROLS
                            startAnimation.fromTo(sliderControls,
                                {
                                    visibility: "hidden",
                                    opacity: 0,
                                },
                                {
                                    visibility: "visible",
                                    opacity: 1,
                                    delay: 0.8,
                                },
                                "entry"
                            )
                        }
                        //DESCRIPTION
                        startAnimation.fromTo(
                            ".pdg-we-are-container .pdg-we-are-adjectives-description",
                            {
                                yPercent: 12,
                                opacity: 0,
                            },
                            {
                                yPercent: 0,
                                opacity: 1,
                                delay: 0.7,
                            },
                            "entry"
                        );

                    //ANIMATION DELAY
                    animationDelay(startAnimation, container);

                    //TITLE ANIMATION
                    textAnimation({
                        tl: startAnimation,
                        split: true,
                        container: ".pdg-we-are-content",
                        elements: [".pdg-we-are-title"],
                        from: { yPercent: 100 },
                        to: {
                            yPercent: 0,
                            delay: 0.1,
                        },
                        keyframe: "entry",
                    });

                    //CHIP ANIMATION
                    chipAnimation({
                        tl: startAnimation,
                        container: ".pdg-we-are-content",
                        keyframe: "entry",
                    });

                    
                    //CHANGE COLOR
                    ScrollTrigger.create({
                        animation: startAnimation,
                        trigger: ".pdg-we-are-container",
                        start: "top 50%",
                        end:"bottom 50%",
                        id: "WEARE",
                        preventOverlaps: true,
                        toggleActions: "play reverse play reverse",
                        // markers: {startColor: "red", endColor: "red", fontSize: '30'},
                        onEnter: () => {
                                gsap.to(".content-menu", {
                                    background: globalState.value.mode === 'normal' ? '#246399' : '#ffc0d5',
                                }),
                                gsap.to("body", {
                                    backgroundColor: globalState.value.mode === 'normal' ? '#246399' : '#ffc0d5',
                                    overwrite: "auto"
                                })
                            },
                        onLeaveBack: () =>{
                                gsap.to("body", {
                                    backgroundColor: globalState.value.mode === 'normal' ? '#fff' : '#ee4266',
                                    overwrite: "auto"
                                })
                                gsap.to( ".content-menu", {
                                    background: globalState.value.mode === 'normal' ? '#fff' : '#ee4266',
                                })
                                closeAll({});
                        },
                        onLeave: () => {
                            closeAll({})
                        },
                    })
                }
            );
        }
    });
</script>

<template>
    <section class="pdg-we-are-container pdg-container">
        <div class="row">
            <!-- CONTENT -->
            <div class="pdg-we-are-content col-md-8">
                <Chip :title="translations?.['we-are']?.tag" class="pink" />
                <h1 class="pdg-we-are-title pdg-header">
                    {{ translations?.['we-are']?.title }}
                </h1>
            </div>

            <!-- ADJGECTIVES CONTAINER -->
            <div class="pdg-we-are-adjectives-container col-md-4">
                <!-- ADJECTIVES -->
                <ul class="pdg-we-are-adjectives pdg-scroll-x">
                    <li
                        class="pdg-we-are-adjective pdg-title-h2-medium"
                        :class="[
                            `pdg-adjective-${index}`,
                            { active: currentAdjectiveIndex === index },
                        ]"
                        v-for="(adjective, index) in adjectives"
                        :key="index"
                        tabindex="0"
                        role="button"
                        @mouseenter="setAdjective(index)"
                        @focusin="setAdjective(index)"
                    >
                        <i class="icon-arrow-left"></i>
                        <span
                            class="pdg-we-are-adjective-label"
                            :data-text="translations?.['we-are']?.adjectives?.[adjective]"
                        >
                            {{ translations?.['we-are']?.adjectives?.[adjective] }}
                        </span>
                    </li>
                </ul>

                <!-- SLIDES CONTAINER -->
                <div class="pdg-we-are-adjectives-slides-container">
                    <!-- STUBBORN -->
                    <div class="pdg-we-are-adjectives-slide pdg-slide-1">
                        <AnimatedImage
                            class="pdg-image-1"
                            :src="`/assets/img/${globalState.mode}/we-are/1/${globalState.mode === 'crazy' ? 'gabriele-in-cammino-impavido': 'product-owner-at-work'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.stubborn?.['1']"
                            sizes="40vw md:25vw"
                        />

                        <AnimatedImage
                            class="pdg-image-2"
                            :src="`/assets/img/${globalState.mode}/we-are/1/${globalState.mode === 'crazy' ? 'nora-marco-dev-designer-impavidi': 'designer-vs-dev'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.stubborn?.['2']"
                            sizes="60vw md:33.33333333vw"
                        />
                    </div>

                    <!-- CURIOUS -->
                    <div class="pdg-we-are-adjectives-slide pdg-slide-2">
                        <AnimatedImage
                            class="pdg-image-1"
                            :src="`/assets/img/${globalState.mode}/we-are/2/${globalState.mode === 'crazy' ? 'igli-alessandro-tecnici-hardware': 'developer-designer-work'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.curious?.['1']"
                            sizes="40vw md:25vw"
                        />

                        <AnimatedImage
                            class="pdg-image-2"
                            :src="`/assets/img/${globalState.mode}/we-are/2/${globalState.mode === 'crazy' ? 'laboratorio-di-disegno-artistico-per-dev': 'in-house-training'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.curious?.['2']"
                            sizes="60vw md:33.33333333vw"
                        />

                        <AnimatedImage
                            class="pdg-image-3"
                            :src="`/assets/img/${globalState.mode}/we-are/2/${globalState.mode === 'crazy' ? 'mirko-giocoliere': 'networking'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.curious?.['3']"
                            sizes="60vw md:25vw"
                        />
                    </div>

                    <!-- CREATIVE -->
                    <div class="pdg-we-are-adjectives-slide pdg-slide-3">
                        <AnimatedImage
                            class="pdg-image-1"
                            :src="`/assets/img/${globalState.mode}/we-are/3/${globalState.mode === 'crazy' ? 'pek-in-vetrina': 'corporate-gadgets'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.creative?.['1']"
                            sizes="40vw md:25vw"
                        />

                        <AnimatedImage
                            class="pdg-image-2"
                            :src="`/assets/img/${globalState.mode}/we-are/3/${globalState.mode === 'crazy' ? 'giacomo-freestyle': 'agile-value'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.creative?.['2']"
                            sizes="60vw md:25vw"
                        />
                    </div>

                    <!-- GEEKS -->
                    <div class="pdg-we-are-adjectives-slide pdg-slide-4">
                        <AnimatedImage
                            class="pdg-image-1"
                            :src="`/assets/img/${globalState.mode}/we-are/4/${globalState.mode === 'crazy' ? 'sexy-matteo' : 'coding-team'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.geeks?.['1']"
                            sizes="40vw md:25vw"
                        />

                        <AnimatedImage
                            class="pdg-image-2"
                            :src="`/assets/img/${globalState.mode}/we-are/4/${globalState.mode === 'crazy' ? 'michele-shocked': 'vr-visor-test'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.geeks?.['2']"
                            sizes="60vw md:33.33333333vw"
                        />

                        <AnimatedImage
                            class="pdg-image-3"
                            :src="`/assets/img/${globalState.mode}/we-are/4/${globalState.mode === 'crazy' ? 'antonio-posing-during-catwalk': 'training-events'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.geeks?.['3']"
                            sizes="60vw md:25vw"
                        />
                    </div>

                    <!-- IMPERFECT -->
                    <div class="pdg-we-are-adjectives-slide pdg-slide-5">
                        <AnimatedImage
                            class="pdg-image-1"
                            :src="`/assets/img/${globalState.mode}/we-are/5/${globalState.mode === 'crazy' ? 'tekken-match': 'blooper-3'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.imperfect?.['1']"
                            sizes="40vw md:25vw"
                        />

                        <AnimatedImage
                            class="pdg-image-2"
                            :src="`/assets/img/${globalState.mode}/we-are/5/${globalState.mode === 'crazy' ? 'gab-multitasking': 'blooper-1'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.imperfect?.['2']"
                            sizes="60vw md:33.33333333vw"
                        />

                        <AnimatedImage
                            class="pdg-image-3"
                            :src="`/assets/img/${globalState.mode}/we-are/5/${globalState.mode === 'crazy' ? 'castello-di-carte': 'blooper-2'}.webp`"
                            :alt="translations?.['we-are']?.['alt-images']?.imperfect?.['3']"
                            sizes="60vw md:30vw"
                        />
                    </div>
                </div>

                <!-- SLIDER CONTROLS -->
                <SliderControls
                    :prevAction="() => prevNextAdjective('prev')"
                    :nextAction="() => prevNextAdjective('next')"
                    class="flex-justify-end pink"
                />

                <!-- DESCRIPTION -->
                <p
                    class="pdg-we-are-adjectives-description pdg-body-2"
                    v-html="translations?.['we-are'].description"
                />
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    //CONTAINER
    .pdg-we-are-container {
        min-height: 100vh;
        color: var(--body-2);

        overflow: hidden;

        position: relative;

        padding-bottom: vh(40);

        @media screen and (min-width: map-get($breakpoints, md)) {
            padding-bottom: vh(60);
        }

        //CONTENT
        .pdg-we-are-content {
            padding-top: vh(40);

            z-index: 1;

            @media screen and (min-width: map-get($breakpoints, md)) {
                padding-top: rem(102);
            }

            //CONTENT TITLE
            .pdg-we-are-title {
                margin-top: rem(16);
                margin-bottom: rem(8);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    margin-bottom: 0;
                }
            }
        }

        //ADJECTIVES CONTAINER
        .pdg-we-are-adjectives-container {
            z-index: 0;

            @media screen and (min-width: map-get($breakpoints, md)) {
                margin-top: vh(232);
            }

            //ADJECTIVES
            .pdg-we-are-adjectives {
                @include flex($align-items: center);

                gap: rem(32);

                list-style: none;
                margin: rem(24) 0 rem(16);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    @include flex($align-items: flex-start, $flex-direction: column);

                    gap: rem(8);

                    margin: 0;
                    margin-bottom: vh(110);

                    padding: 0;
                }

                //ADJECTIVE
                .pdg-we-are-adjective {
                    position: relative;

                    //ADJECTIVE LEFT DOT
                    &:nth-child(1n + 2) {
                        &:before {
                            content: "";

                            display: block;

                            width: rem(4);
                            height: rem(4);

                            background: var(--body-2);

                            border-radius: rem(50);

                            position: absolute;
                            top: 50%;
                            left: rem(-16);

                            transform: translateY(-50%);

                            @media screen and (min-width: map-get($breakpoints, md)) {
                                display: none;
                            }
                        }
                    }

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        @include flex($align-items: center);
                    }

                    //ARROW
                    .icon-arrow-left {
                        display: none;

                        @include typography(40, 400, 40);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            display: block;
                            transition: $transition;
                            font-size: 0;
                        }
                    }

                    //LABEL
                    .pdg-we-are-adjective-label {
                        @media screen and (max-width: map-get($breakpoints, md)) {
                            @include hover-link-change-font-weight;
                        }
                    }

                    //ACTIVE
                    &.active {
                        //ARROW
                        .icon-arrow-left {
                            @media screen and (min-width: map-get($breakpoints, md)) {
                                margin-right: rem(12);

                                font-size: rem(40);
                            }

                            @media screen and (min-width: map-get($breakpoints, lg)) {
                                font-size: vw(40, 1440);
                            }
                        }

                        //LABEL
                        .pdg-we-are-adjective-label {
                            font-weight: 700;
                        }
                    }
                }
            }

            //SLIDES CONTAINER
            .pdg-we-are-adjectives-slides-container {
                height: vh(240);
                min-height: rem(160);

                margin: rem(40) 0;

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    height: vh(340);
                    min-height: rem(280);
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    width: 100%;
                    height: 100vh;

                    margin: 0;

                    padding: 0 rem(map-get($gutter-container, md));

                    position: absolute;
                    top: 0;
                    left: 0;

                    z-index: -1;
                }

                //SLIDE
                .pdg-we-are-adjectives-slide {
                    @include flex($align-items: flex-start);

                    gap: rem(16);

                    height: 100%;

                    @extend .pdg-scroll-x;

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        width: calc(100% - rem(map-get($gutter-container, md)) * 2);

                        margin: 0;

                        padding: 0;

                        position: absolute;
                        top: 0;
                        left: rem(map-get($gutter-container, md));
                    }

                    //SLIDE 1 
                    &.pdg-slide-1,
                    &.pdg-slide-3 {
                        .pdg-animated-image-container {

                            &.pdg-image-1 {
                                width: calc(40% - rem(8));

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    width: 25%;

                                    left: 0;
                                    bottom: 0;
                                }

                                @media screen and (min-width: map-get($breakpoints, lg)) {
                                    height: vh(380);
                                }

                                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                                    width: 33.33333333%;

                                    left: 0;
                                    bottom: calc(8.33333333% / 2);
                                }
                            }

                            &.pdg-image-2 {
                                width: calc(60% - rem(8));

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    width: 25%;
                                    height: 25%;

                                    left: 33.33333333%;
                                    bottom: 41.66666667%;
                                }

                                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) and (min-height: map-get($breakpoints, md)) {
                                    width: 50%;
                                    height: 25%;

                                    left: 8.33333333%;
                                    bottom: calc(50% - 8.33333333% / 2);
                                }

                                @media screen and (min-width: map-get($breakpoints, lg)) {
                                    left:33.33333333%;
                                }
                            }
                        }
                    }

                    //SLIDE 2 - 3 - 4 - 5 - 6
                    
                    &.pdg-slide-2,
                    &.pdg-slide-4,
                    &.pdg-slide-5,
                    &.pdg-slide-6 {
                        .pdg-animated-image-container {
                            @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) and (min-height: 1100px) {
                                max-height: rem(240);
                            }

                            &.pdg-image-1 {
                                width: calc(40% - rem(8));

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    width: calc(25% - (8.33333333% / 4));

                                    left: 0;
                                    bottom: 8.33333333%;

                                    @media screen and (min-height: 800px) {
                                        bottom: 25%;
                                    }
                                }

                                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) and (min-height: 1100px) {
                                    width: 41.66666667%;

                                    left: 0;
                                    bottom: 25%;
                                }

                                @media screen and (min-width: map-get($breakpoints, lg)) {
                                    width: calc(25% - (8.33333333% / 2));
                                }
                            }

                            &.pdg-image-2 {
                                width: calc(60% - rem(8));

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    width: 33.33333333%;

                                    top: 25%;
                                    left: calc(
                                        16.66666667% + rem(map-get($gutter-container, md)) *
                                            1.5
                                    );
                                    padding-bottom: rem(32);
                                }

                                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) and (min-height: 1100px) {
                                    width: 50%;

                                    top: calc(25% + 8.33333333% / 1.5);
                                    left: 8.33333333%;
                                }

                                @media screen and (min-width: map-get($breakpoints, lg)) {
                                    left: calc(25%);
                                }
                            }

                            &.pdg-image-3 {
                                width: calc(60% - rem(8));

                                @media screen and (min-width: map-get($breakpoints, md)) {
                                    width: calc(25%);

                                    left: calc(
                                        16.66666667% + rem(map-get($gutter-container, md)) *
                                            1.5
                                    );
                                    bottom: 0;
                                }

                                @media screen and (min-width: map-get($breakpoints, md)) and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) and (min-height: 1100px) {
                                    width: 41.66666667%;

                                    left: 8.33333333%;
                                }

                                @media screen and (min-width: map-get($breakpoints, lg)) {
                                    left: calc(25%);
                                }
                            }
                        }
                    }
                }

                //IMAGE
                .pdg-animated-image-container {
                    height: vh(180);
                    min-height: rem(120);

                    @media screen and (min-width: map-get($breakpoints, sm)) {
                        height: vh(280);
                        min-height: rem(240);
                    }

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        position: absolute;
                        height: vh(320);
                    }

                    &:nth-child(2n + 1) {
                        margin-top: auto;

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            margin-top: 0;
                        }
                    }

                    &:deep(.pdg-animated-image-overlay) {
                        background: var(--dark-pink);
                    }
                }
            }

            //SLIDER CONTROLS
            .pdg-slider-controls {
                margin-bottom: rem(16);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    display: none;
                }
            }

            //DESCRIPTION
            .pdg-we-are-adjectives-description {
                width: 66.66666667%;

                margin: 0;

                color: var(--body-4);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    width: 83.33333333%;
                }
            }
        }
    }
</style>
