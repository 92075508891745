<template>
    <footer class="pdg-footer-container pdg-container-height">
        <div id="wrap" class="pdg-mouse-container" ref="mouseContainer">
            <div v-if="globalState.mode === 'crazy'">
                <div v-for="(item, index) in startArray" :key="index">
                    <NuxtImg
                        class="pdg-follow-image" 
                        :src="translations?.footer?.sticker?.[item]?.['img-sticker']"
                        :alt="translations?.footer?.sticker?.[item]?.['img-alt']"
                        :style="{'z-index': reversedArray[index] + 1}"
                        loading="lazy"
                        sizes="150px"
                    />
                </div>
            </div>
        <div class="pdg-container">
            <div class="pdg-relative-container">
            <div class="row">
                <div class="col-md-11 offset-md-1">
                    <div class="pdg-asterisk">
                        <NuxtImg
                            class="pdg-asterisk-img"
                            src="/assets/img/asterisk.svg"
                            alt="asterisk"
                            loading="lazy"
                        />
                    </div>

                    <div class="pdg-footer-title">
                        <h1 class="pdg-display">
                            {{ translations?.footer?.title?.main }}
                        </h1>
                        <a
                            href="mailto: info@paradigma.me"
                            class="pdg-display-2"
                        >
                            {{ translations?.footer?.title?.sub }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="pdg-info-container row">
                <div
                    class="pdg-company-info pdg-body-2 pdg-fade-in col-md-4 offset-md-1"
                >
                    <p class="pdg-paragraph-info">
                        {{ translations?.footer?.companyInfo?.companyName }}
                    </p>
                    <address
                        class="pdg-address-info"
                        v-html="translations?.footer?.companyInfo?.address"
                    ></address>
                    <p>
                        {{ translations?.footer?.companyInfo?.vatNumber }}
                    </p>
                    <a
                        class="pdg-link-decoration pdg-animated-underline"
                        href="mailto: info@paradigma.me"
                    >
                        info@paradigma.me
                    </a>
                </div>

                <ul class="pdg-policy-list pdg-body-3 pdg-fade-in col-md-3">
                    <li class="pdg-policy-list-element">
                        <a
                            class="pdg-link-decoration pdg-animated-underline"
                            href="https://www.iubenda.com/privacy-policy/37616530"
                            target="_blank"
                        >
                            {{ translations?.footer?.policyList?.privacyPolicy }}
                        </a>
                    </li>
                    <li class="pdg-policy-list-element">
                        <a
                            class="pdg-link-decoration pdg-animated-underline"
                            href="https://www.iubenda.com/privacy-policy/37616530/cookie-policy"
                            target="_blank"
                        >
                            {{ translations?.footer?.policyList?.cookiePolicy }}
                        </a>
                    </li>
                    <li class="pdg-policy-list-element">
                        <NuxtLink
                            class="pdg-link-decoration pdg-animated-underline"
                            to="/assets/politica-paradigma.pdf"
                            target="_blank"
                            external
                        >
                            {{ translations?.footer?.policyList?.policy }}
                        </NuxtLink>
                    </li>
                </ul>

                <ul class="pdg-social pdg-fade-in col-md-3">
                    <li>
                        <a
                            class="pdg-social-icon"
                            target="_blank"
                            href="https://www.linkedin.com/company/paradigma-innovation/"
                            aria-label="linkedin"
                        >
                            <i class="icon-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a
                            class="pdg-social-icon"
                            target="_blank"
                            href="https://www.facebook.com/ParadigmaInnovation"
                            aria-label="facebook"
                        >
                            <i class="icon-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a
                            class="pdg-social-icon"
                            target="_blank"
                            href="https://www.behance.net/paradiginnovation?tracking_source=search_users|paradigma"
                            aria-label="behance"
                        >
                            <i class="icon-behance"></i>
                        </a>
                    </li>
                    <li>
                        <a
                            class="pdg-social-icon"
                            target="_blank"
                            href="https://www.instagram.com/paradigma_inn/"
                            aria-label="instagram"
                        >
                            <i class="icon-instagram"></i>
                        </a>
                    </li>
                </ul>

                <button
                    type="button"
                    class="pdg-back-to-top"
                    @click="scrollToTop()"
                    @mouseover="scale"
                    @mouseleave="scale"
                    @focusin="scale"
                    @focusout="scale"
                    :aria-label="translations?.footer?.['back-to-top']"
                >
                    <i class="icon-arrow-up"></i>
                </button>
            </div>
        </div>

            <div class="pdg-rights-languages pdg-fade-in row">
                <div
                   class="col-md-3 order-md-3">
                    <ul class="pdg-switch-language">
                        <li
                            class="pdg-language-list pdg-body-3"
                            v-for="(localeObject, index) in availableLocales"
                            :key="index">
                                <a
                                    class="pdg-style-link pdg-animated-underline"
                                    :class="{'router-link-exact-active': locale === (localeObject as LocaleObject).code}"
                                    :href="switchLocalePath((localeObject as LocaleObject).code)">
                                    <span class="pdg-span-inline-block"> {{ (localeObject as LocaleObject).name }} </span>
                                </a>
                        </li>
                    </ul>
                </div>

                <p class="pdg-label-2 pdg-margin-rights col-6 col-sm-3 col-md-4 order-md-1 offset-md-1">
                    {{ translations?.footer?.['right-reservered']?.copyright }}
                </p>

                <p class="pdg-label-2 col-6 col-sm-3 col-md-3 order-md-2">
                    {{ translations?.footer?.['right-reservered']?.rightsReserved }}
                </p>
            </div>
        </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";
    import { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables';
    const globalState = useGlobalState();

    const startArray = Array.from(Array(10).keys());
    const reversedArray = [...startArray].reverse();
    
    const { locales, locale } = useI18n();
    const switchLocalePath = useSwitchLocalePath()
    const availableLocales = computed(() => locales.value.filter((locale: string|LocaleObject) => (locale as LocaleObject).code !== locale));
    const followBox: string = "#wrap .pdg-follow-image";
    const mouseContainer = ref<HTMLDivElement>();
    
    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    const scrollToTop = () =>
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    const scale = ({ target, type }: Event) => {
        const t = (target as HTMLElement).closest(
            ".pdg-back-to-top"
        ) as HTMLButtonElement | null;

        if (t) {
            let value = 1;

            switch (type) {
                case "mouseover":
                case "focusin":
                    value = 1.2;
                    break;

                default:
                    value = 1;
                    break;
            }

            t.style.transform = `scale(${value})`;
        }
    };

    onMounted(() => {
        if (process.client) {
            
            //CONTAINER
            const container = document.querySelector(".pdg-footer-container") as HTMLElement;

            if(container){
                const imgSticker = document.querySelectorAll('.pdg-follow-image') as NodeListOf<HTMLImageElement>;

                const mm = gsap.matchMedia(), breakPoint = 1024;
                mm.add({
                    isDesktop: `(min-width: ${breakPoint}px)`,
                    isMobile: `(max-width: ${breakPoint - 1}px)`,
                }, ({ conditions }) => {
                    const { isDesktop } = conditions as gsap.Conditions;

                    const startAnimation = gsap
                    .timeline()
                    .fromTo(
                        ".pdg-footer-container .pdg-footer-title",
                        { opacity: 0 },
                        { opacity: 1, duration: 0.1 },
                        "entry +=0"
                    )
                    .fromTo(
                        ".pdg-footer-container .pdg-asterisk-img",
                        { scale: 0, rotate: 0, opacity: 0 },
                        { scale: 1, rotate: "180deg", opacity: 1 },
                        "entry"
                    )
                    .fromTo(
                        ".pdg-footer-container .pdg-fade-in",
                        { yPercent: 50, opacity: 0 },
                        { yPercent: 0, stagger: 0.1, duration: 0.4, opacity: 1 },
                        "entry +=0"
                    )
                    .fromTo(
                        ".pdg-footer-container .pdg-back-to-top",
                        { scale: 0, rotateY: 0, opacity: 0 },
                        { scale: 1, duration: 0.4, opacity: 1, transition: "0.3s ease-in-out", },
                        "entry +=0"
                    )

                    animationDelay(startAnimation, container);

                    textAnimation({
                        split: true,
                        tl: startAnimation,
                        container: ".pdg-footer-title",
                        elements: [".pdg-display", ".pdg-display-2"],
                        from: { yPercent: 100, opacity: 0 },
                        to: { yPercent: 0, opacity: 1 },
                        keyframe: "entry",
                    });

                    ScrollTrigger.create({
                    animation: startAnimation,
                    trigger: ".pdg-footer-container",
                    start: "top 50%",
                    end: "bottom 50%",
                    preventOverlaps: true,
                    id: "FOOTER",
                    toggleActions: "play reverse play reverse",
                    // markers: {startColor: "black", endColor: "red", fontSize: '30'},
                    onEnter: () => {
                        setTimeout (() => {
                            gsap.to("body", {
                                backgroundColor: '#0a2843',
                            });
                            gsap.to( ".content-menu", {
                                background: '#0a2843'
                            })
                        }, 0)
                    },
                    onLeaveBack: () => {
                        setTimeout (() => {
                            gsap.to("body", {
                                backgroundColor: globalState.value.mode === 'normal' ? '#246399' : '#c3f73a',
                            });
                            gsap.to( ".content-menu", {
                                background: globalState.value.mode === 'normal' ? '#246399' : '#c3f73a',
                            })
                        }, 0)
                    }
                });

                    if (globalState.value.mode === 'crazy') {
                        let mousePosition = { clientX: 0, clientY: 0 };

                        if (isDesktop) {
                            gsap.set(followBox, {
                                xPercent: -50,
                                yPercent: -20,
                            });

                            startAnimation.fromTo(
                                imgSticker,
                                { opacity: 0 },
                                { opacity: 1 },
                                "entry +=0"
                            )

                            const handleStickerMove =
                                ({ clientX, clientY }: Partial<MouseEvent>) => {

                                    if (clientX && clientX >= 0 && clientY && clientY >= 0 && mouseContainer) {
                                        mousePosition.clientX = clientX;
                                        mousePosition.clientY = clientY;

                                        const footerTop = mouseContainer.value?.getBoundingClientRect();

                                        if (footerTop && clientY >= footerTop.top) {

                                            gsap.to(followBox, {
                                                duration: 0.5,
                                                overwrite: "auto",
                                                x: clientX,
                                                y: clientY - (mouseContainer.value?.getBoundingClientRect().y || 0),
                                                stagger: 0.15,
                                                ease: "none"
                                            });

                                            gsap.timeline({
                                                defaults: { duration: 0.5, ease: "none" }
                                            })
                                            .to(followBox,
                                            {
                                                overwrite: "auto",
                                                stagger: { amount: 0.15, from: "start", ease: "none" }
                                            })
                                            .to(followBox,
                                            {
                                                overwrite: "auto",
                                                stagger: { amount: 0.15, from: "end", ease: "none" }
                                            }, "+=2.5")
                                        }
                                    }
                                };
                            container.addEventListener("mousemove", handleStickerMove);
                            window.addEventListener("scroll", () => {
                                if (container.getBoundingClientRect().top <= (window.innerHeight / 2)) {
                                    handleStickerMove(mousePosition)
                                }
                            })
                        } else {
                            container.removeEventListener("mousemove", () => {});
                            window.removeEventListener("scroll", () => {});
                            gsap.set(
                                imgSticker,
                                { opacity: 0 },
                            )
                        };
                    }
                })
            }
        }
    });
</script>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .pdg-footer-container {
        color: var(--light);
        position: relative;
        overflow: visible;

        .pdg-mouse-container {
            width: 100%;
            min-height: 100vh;
            padding: rem(116) 0 rem(56);

            @media screen and (min-width: map-get($breakpoints, md)) {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: rem(148) 0 rem(36);
            }

            .pdg-follow-image {
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                width: auto;
                height: rem(250);
            }
        }

        .pdg-relative-container {
            position: relative;
        }

        .pdg-container {
            z-index: 15;
            .pdg-asterisk {
                .pdg-asterisk-img {
                    width: auto;
                    height: rem(32);

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        height: rem(66);
                    }
                }
            }

            .pdg-footer-title {
                padding-top: rem(16);

                .pdg-display {
                    margin: 0;
                }

                .pdg-display-2 {
                    position: relative;
                    display: inline-block;
                    color: var(--light);
                    text-decoration: none;

                    &:hover,
                    &:focus  {
                        color: var(--green);

                        &::after {
                            background-color: var(--green);
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: rem(3);
                        background-color: var(--light);
                        bottom: 0;
                        left: 0;
                    }
                }
            }

            .pdg-link-decoration {
                text-decoration: none;
                color: currentColor;
            }

            .pdg-info-container {
                align-items: flex-start;
                padding-top: rem(32);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    padding-top: rem(80);
                }
            }

            .pdg-company-info {
                .pdg-paragraph-info {
                    font-weight: 500;
                    margin-bottom: 0;
                }

                .pdg-address-info {
                    margin-bottom: 0;
                }
            }

            .pdg-policy-list,
            .pdg-social {
                list-style: none;
                margin: 0;
                line-height: rem(30);
            }

            .pdg-policy-list {
                padding-top: rem(32);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    padding-top: 0;
                }
            }

            .pdg-social {
                display: flex;
                padding-top: rem(80);
                font-size: rem(30);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    padding-top: 0;
                }

                .pdg-social-icon {
                    display: flex;
                    width: rem(40);
                    height: rem(40);
                    padding: rem(4);
                    justify-content: center;
                    align-items: center;
                    background-color: var(--light);
                    color: var(--dark);
                    border-radius: 100%;
                    margin-right: rem(16);
                    text-decoration: none;
                    transition: transform 0.3s ease-in-out;

                    &:hover,
                    &:focus {
                        background-color: var(--green);
                    }
                }
            }

            .pdg-back-to-top {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--green);
                color: var(--blue);
                width: rem(62);
                height: rem(62);
                border-radius: 100%;
                position: absolute;
                right: 0;
                bottom: rem(8);
                cursor: pointer;
                border: none;
                font-size: rem(22);
                line-height: rem(22);
                z-index: 50;

                @media screen and (min-width: map-get($breakpoints, md)) {
                    bottom: rem(-16);
                }

                @media screen and (min-width: map-get($breakpoints, lg)) {
                    bottom: rem(16);
                }

                &:focus {
                    border: rem(1) solid var(--light-blue);
                }
            }

             .pdg-rights-languages {
                padding-top: rem(48);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    align-items: center;
                }

                .pdg-label-2 {
                    margin-bottom: 0;
                }

                .pdg-switch-language {
                    display: flex;
                    padding-bottom: rem(32);
                    margin-bottom: 0;
                    padding-left: 0;

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        padding-bottom: 0;
                    }

                    .pdg-language-list {
                        list-style: none;

                        &:first-child::after {
                            content: "-";
                            padding: 0 rem(16);
                        }

                        .pdg-span-inline-block {
                            display: inline-block;
                        }
                    }

                    .pdg-style-link {
                        text-decoration: none;
                        color: var(--white);
                        
                        &:hover,
                        &:focus,
                        &:focus-visible,
                        &.router-link-exact-active { 
                            &::after {
                                width: 100%;
                            }
                        }
                        &.router-link-exact-active {
                            color: var(--green);
                            transition: transform 0.3s ease-in-out;
                        }
                    }
                }
            }
        }
    }
</style>