<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";
    import { textAnimation } from "../utils/animations.utils";

    const globalState = useGlobalState();
    const props = defineProps(["loaderIsActive"]);
    const { locale } = useI18n();

    //VIDEO CONTAINER
    let videoContainer: HTMLDivElement;

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    watchEffect(() => {
        if (process.client) {
            const htmlPage = document.querySelector("html") as HTMLHtmlElement;
            if ( htmlPage && !props.loaderIsActive) {
                htmlPage.classList.add("page-scroll");
            }
        }
    })

    onMounted(() => {
        if (process.client) {

            //HEADER CONTAINERS
            const headerContainer = document.querySelector(
                ".pdg-header-container"
            ) as HTMLElement;

            //MODAL CONTAINER
            const modalContainer = document.querySelector(
                ".pdg-modal-container"
            ) as HTMLElement;

            //VIDEO CONTAINER
            videoContainer = document.querySelector(
                ".pdg-video-container"
            ) as HTMLDivElement;

            //PLAY VIDEO BUTTON
            const playVideoBtn = document.querySelector(
                ".pdg-header-video-container .pdg-play-video-btn"
            ) as HTMLElement;

            const htmlPage = document.querySelector("html") as HTMLHtmlElement;
            if (htmlPage) {
                htmlPage.classList.add("page-scroll");
            }

            let mousePosition = { clientX: 0, clientY: 0 };

            const handleMouseMove = ({ clientX, clientY }: Partial<MouseEvent>) => {
                if (clientX && clientX >= 0 && clientY && clientY >= 0) {
                    mousePosition.clientX = clientX;
                    mousePosition.clientY = clientY;

                    playVideoBtn.style.left = `${
                        clientX -
                        modalContainer.offsetLeft -
                        playVideoBtn.clientWidth / 2
                    }px`;

                    playVideoBtn.style.top = `${
                        clientY -
                        modalContainer.offsetTop -
                        playVideoBtn.clientHeight / 2 -
                        (Math.ceil(modalContainer.getBoundingClientRect().y) -
                            modalContainer.offsetTop)
                    }px`;

                    if (playVideoBtn.style.opacity === "0" || !playVideoBtn.style.opacity) {
                        gsap.to(
                            playVideoBtn,
                            {
                                visibility: "visible",
                                opacity: 1,
                            }
                        );
                    }
                }
            };


            if (headerContainer && modalContainer && videoContainer) {
                //CLOSE MODAL ON keydown
                window.addEventListener("keydown", handleCloseModal);

                //VIDEO DESCRIPTION
                const videoDescription = videoContainer.querySelector(
                    ".pdg-video-description"
                ) as HTMLParagraphElement;

                //GSAP BREAKPOINTS
                const mm = gsap.matchMedia();
                const breakPoint = 1024;
                const smBreakpoint = 768;

                const mobileMiniBreakPoint = 375;
                const mobileVerticalBreakPoint = 632;
                const mobileHorizontalBreakPoint = 700;

                mm.add(
                    {
                        isHover: '(hover: hover)',
                        isDesktop: `(min-width: ${breakPoint}px) and (min-height: ${mobileHorizontalBreakPoint + 0.1}px)`,
                        isTablet: `(min-width: ${smBreakpoint}px)`,
                        isMobile: `(max-width: ${breakPoint - 0.1}px)`,
                        isMobileHorizontal: `(min-width: ${mobileMiniBreakPoint}px) and (max-height: ${mobileHorizontalBreakPoint}px) and (orientation: landscape)`,
                        isMobileMini: `(max-width: ${mobileHorizontalBreakPoint}px) and (max-height: ${mobileHorizontalBreakPoint}px)`,
                        isMobileMiniVertical: `(max-width: ${mobileVerticalBreakPoint}px) and (max-height: ${mobileVerticalBreakPoint}px) and (orientation: portrait)`,
                    },
                    ({ conditions }) => {

                        //START ANIMATION
                        const startAnimation = gsap.timeline();

                        //ANIMATION DELAY
                        animationDelay(startAnimation, headerContainer);

                        textAnimation({
                            tl: startAnimation,
                            split: true,
                            container: ".pdg-header-content",
                            elements: [".pdg-title-h1", ".pdg-subhead-2"],
                            from: { yPercent: 100},
                            to: {
                                yPercent: 0,
                                opacity: 1,
                                delay: 0.1,
                            },
                            keyframe: "entry",
                        });

                        startAnimation.to(
                            videoContainer,
                            {
                                opacity: 1,
                                delay: 0.1,
                            },
                            "entry"
                        );

                        // IS DESKTOP
                        const {
                            isHover,
                            isDesktop,
                            isTablet,
                            isMobileHorizontal,
                            isMobileMini,
                            isMobileMiniVertical,
                        } = conditions as gsap.Conditions;

                        //MAIN TIMELINE
                        let tl = gsap.timeline();

                        textAnimation({
                            tl: tl,
                            container: ".pdg-header-content",
                            elements: [".pdg-title-h1", ".pdg-subhead-2"],
                            from: { yPercent: 0 },
                            to: { yPercent: 100 },
                            keyframe: "open",
                        });

                        const duration = isMobileMiniVertical ? 4 : 1;

                        tl.fromTo(
                            videoContainer,
                            {
                                yPercent: 0,
                            },
                            {
                                yPercent:
                                    isMobileHorizontal || isMobileMiniVertical
                                        ? -100
                                        : isDesktop
                                        ? -50
                                        : -48,
                                paddingBottom: "80px",
                                height: "100vh",
                                duration,
                            },
                            
                            "open"
                        );
                        if (playVideoBtn) {
                            videoContainer.removeEventListener("mousemove", handleMouseMove);
                            window.removeEventListener("scroll", () => 
                                handleMouseMove(mousePosition)
                            );
                        }


                        if (isHover || isDesktop) {
                            if (playVideoBtn) {
                                videoContainer.addEventListener("mousemove", handleMouseMove);

                                window.addEventListener("scroll", () => {
                                    if (videoContainer.getBoundingClientRect().bottom >= 0) {
                                        handleMouseMove({
                                            clientX: mousePosition.clientX,
                                            clientY: mousePosition.clientY,
                                        })
                                    }
                                }
                                );
                            }

                        }

                        if (isDesktop) {
                            modalContainer.style.height = "calc(100%)";

                                tl
                                //OPEN
                                .to(
                                    ".pdg-modal-container",
                                    {
                                        height: "auto",
                                        yPercent: 50,
                                    },
                                    "open"
                                )
                                .to(
                                    ".pdg-arrow-container",
                                    {
                                        yPercent: 200,
                                    },
                                    "open"
                                )
                                //START
                                .to(
                                    ".pdg-arrow-container .pdg-arrow-svg",
                                    {
                                        yPercent: 50,
                                        opacity: 0,
                                    },
                                    "start"
                                )
                                .to(
                                    ".pdg-arrow-container .pdg-arrow-description",
                                    {
                                        yPercent: -50,
                                        opacity: 0,
                                    },
                                    "start"
                                )
                                //SHOW
                                .to(
                                    ".pdg-arrow-container",
                                    {
                                        width: 0,
                                        height: 0,
                                    },
                                    "show"
                                )
                                .to(
                                    ".pdg-modal-container",
                                    {
                                        yPercent: 0,
                                        overflow: "initial",
                                    },
                                    "show"
                                )
                                .to(
                                    ".pdg-modal-container .pdg-header-video-container",
                                    {
                                        margin: 0,
                                    },
                                    "show"
                                )
                                //END
                                .fromTo(
                                    ".pdg-modal-container .pdg-video-description",
                                    { yPercent: 100 },
                                    {
                                        height: "auto",
                                        marginTop: "1rem",
                                        yPercent: 0,
                                        opacity: 1,
                                    },
                                    "end"
                                )
                        } else if (isMobileHorizontal) {
                            videoDescription && (videoDescription.style.height = "auto");

                            isMobileMini &&
                                tl.to(".pdg-modal-container .pdg-video-description", {
                                    marginTop: "1rem",
                                });

                            tl
                                //OPEN
                                .fromTo(
                                    ".pdg-video-container .pdg-fade-in",
                                    {
                                        opacity: 0,
                                    },
                                    {
                                        opacity: 1,
                                        stagger: -0.1,
                                    }
                                )
                            if (playVideoBtn) {
                                tl.to(
                                    playVideoBtn,
                                    {
                                        visibility: "visible",
                                        opacity: 1,
                                    },
                                    "end"
                                );
                            }
                        } else {
                            modalContainer.style.height = "auto";

                            const delay = +!!isMobileMiniVertical * 3;

                            tl
                                //OPEN
                                .to(
                                    ".pdg-arrow-container",
                                    {
                                        y: 150,
                                        height: 0,
                                        margin: 0,
                                        opacity: 0,
                                        duration,
                                        delay,
                                    },
                                    isMobileMiniVertical ? "hide-arrow" : "open"
                                )
                                .to(
                                    ".pdg-arrow-container .pdg-arrow-svg",
                                    {
                                        yPercent: 100,
                                        opacity: 0,
                                        duration,
                                        delay,
                                    },
                                    isMobileMiniVertical ? "hide-arrow" : "open"
                                )
                                // .to(
                                //     videoContainer,
                                //     {
                                //         paddingRight: "1rem",
                                //         duration,
                                //     },
                                //     isMobileMiniVertical ? "hide-arrow" : "open"
                                // );

                            //IMAGE MASK CUT ONLY IN NORMAL MOBILE
                            !isMobileMiniVertical &&
                                tl.fromTo(
                                    ".pdg-modal-container",
                                    { maxHeight: `${isTablet ? 36 : 26}vh` },
                                    {
                                        maxHeight: "100vh",
                                    },
                                    isMobileMiniVertical ? "hide-arrow" : "open"
                                );

                            tl
                                //SHOW
                                .fromTo(
                                    ".pdg-modal-container",
                                    { width: `${isTablet ? 75 : 83.33333333}%` },
                                    {
                                        width: "100%",
                                        duration,
                                    },
                                    isMobileMiniVertical ? "show-paragraph" : "show"
                                )
                                .to(
                                    ".pdg-modal-container .pdg-header-video-container",
                                    {
                                        margin: 0,
                                        overflow: isHover ? "hidden" : "initial",
                                        duration,
                                    },
                                    isMobileMiniVertical ? "show-paragraph" : "show"
                                )
                                .to(
                                    ".pdg-modal-container .pdg-video-description",
                                    {
                                        marginTop: "1rem",
                                        duration,
                                    },
                                    isMobileMiniVertical ? "show-paragraph" : "show"
                                )
                            if (playVideoBtn) {
                                
                                tl.to(
                                    playVideoBtn,
                                    {
                                        visibility: "visible",
                                        opacity: "1",
                                        duration,
                                    },
                                    isMobileMiniVertical ? "show-paragraph" : "show"
                                )
                            }
                                tl.fromTo(
                                    ".pdg-modal-container .pdg-video-description",
                                    { yPercent: 50 },
                                    {
                                        height: "auto",
                                        yPercent: 0,
                                        opacity: 1,
                                        duration,
                                    }
                                );
                        }
                        
                            ScrollTrigger.create({
                                animation: tl,
                                trigger: ".pdg-header-container",
                                start: "top top",
                                end: "center",
                                preventOverlaps: true,
                                pin: true,
                                scrub: 1,
                                anticipatePin: 1,
                                invalidateOnRefresh: true,
                                snap: isMobileMiniVertical ? 0 : 1,
                                id: "HEADER",
                            });
                    }
                );
            }
        }
    });

    onUnmounted(() => {
        window.removeEventListener("keydown", handleCloseModal);
    });

    const autoScroll = () =>
        window.scrollTo({
            top: videoContainer?.offsetTop,
            behavior: "smooth",
        });

    const modalIsOpen = ref(false);

    const toggleModal = () => {
        //INVERT MODAL IS OPEN
        modalIsOpen.value = !modalIsOpen.value;

        //GET VIDEO
        const modalVideo = document.querySelector(".pdg-modal-video") as HTMLVideoElement;

        //SET VIDEO CURRENT TIME TO 0
        modalIsOpen.value && (modalVideo.currentTime = 0);

        //VIDEO PLAY PAUSE
        modalVideo?.[modalIsOpen.value ? "play" : "pause"]();

        //ADD / REMOVE "scroll-block" CLASS
        document
            .querySelector("html, body")
            ?.classList.toggle("scroll-block", modalIsOpen.value);

        blockScroll();
    };

    const handleCloseModal = ({ key }: { key: string }) =>
        key === "Escape" && modalIsOpen.value && toggleModal();

    const handleBackdropCloseModal = ({ target }: Event) =>
        (target as HTMLElement).className.includes("pdg-modal-video-container") &&
        modalIsOpen.value &&
        toggleModal();
</script>

<template>
    <!-- HEADER SECTION CONTAINER -->
    <section class="pdg-header-section-container" id="we-are-rebranding">
        <Transition>
            <!-- MODAL VIDEO CONTAINER -->
            <div
                class="pdg-modal-video-container pdg-container"
                @click="handleBackdropCloseModal"
                role="button"
                tabindex="0"
                :aria-pressed="modalIsOpen"
                :aria-hidden="!modalIsOpen"
                v-show="modalIsOpen"
            >
                <!-- MODAL VIDEO CLOSE BUTTON (SOSTITUIRE CON ICONA FONT) -->
                <button
                    class="pdg-modal-close-btn"
                    @click="toggleModal"
                    :tabindex="modalIsOpen ? 0 : -1"
                >
                    <span class="pdg-button">
                        {{ translations?.header?.modal?.['close-btn'] }}
                    </span>

                    <svg
                        width="32"
                        height="37"
                        viewBox="0 0 32 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.62586 23.5663C3.04008 22.9805 3.04008 22.0307 3.62586 21.4449L24.132 0.938851C24.7177 0.353065 25.6675 0.353065 26.2533 0.938851C26.8391 1.52464 26.8391 2.47439 26.2533 3.06017L5.74718 23.5663C5.1614 24.1521 4.21165 24.1521 3.62586 23.5663ZM3.62586 33.7584C3.04008 34.3442 3.04008 35.294 3.62586 35.8797C4.21165 36.4655 5.1614 36.4655 5.74718 35.8797L26.2533 15.3737C26.8391 14.7879 26.8391 13.8381 26.2533 13.2523C25.6675 12.6665 24.7177 12.6665 24.132 13.2523L3.62586 33.7584Z"
                            fill="currentColor"
                        />
                    </svg>
                </button>

                <!-- MODAL VIDEO ROW -->
                <div class="pdg-modal-video-container-row row">
                    <!-- MODAL VIDEO -->
                    <video
                        class="pdg-modal-video col-md-10 offset-md-1"
                        src="/assets/paradigma-rebranding-2024.mp4"
                        type="video/mp4"
                        controls
                        loading="lazy"
                        preload="metadata"
                        poster="/assets/img/header-video-poster.webp"
                    />
                </div>
            </div>
        </Transition>

        <!-- HEADER CONTAINER -->
        <div class="pdg-header-container">
            <!-- HEADER CONTENT -->
            <div class="pdg-header-content pdg-container">
                <div class="row">
                    <h1
                        class="pdg-title-h1 col-11 col-md-10"
                        v-html="translations?.header?.title"
                    />
                    <p
                        class="pdg-subhead-2 col-10 col-md-11 offset-2 offset-md-1"
                        v-html="translations?.header?.subtitle"
                    />
                </div>
            </div>

            <!-- VIDEO CONTAINER -->
            <div class="pdg-video-container pdg-video-container-header pdg-container">
                <div class="pdg-video-container-row row">
                    <!-- MODAL CONTAINER -->
                    <div class="pdg-modal-container offset-2 offset-sm-0 col-10 col-sm-9">
                        <!-- HEADER VIDEO CONTAINER -->
                        <div
                            class="pdg-header-video-container pdg-fade-in"
                            role="button"
                            tabindex="0"
                            @click="toggleModal"
                            @keydown.space.prevent="toggleModal"
                            @keydown.enter.prevent="toggleModal"
                            :aria-pressed="modalIsOpen"
                        >
                            <NuxtImg
                                class="pdg-header-video-poster"
                                src="/assets/img/header-video-poster.svg"
                                :alt="translations?.header?.rebranding?.['video-poster']"
                                preload
                                sizes="83.33333333vw md:75vw"
                                densities="x2"
                            />

                            <HeaderPlayVideoButton />
                        </div>

                        <p
                            class="pdg-body-6 pdg-video-description pdg-fade-in"
                            v-html="translations?.header?.rebranding?.description"
                        />
                    </div>

                    <!-- ARROW CONTAINER -->
                    <div class="pdg-arrow-container col-12 col-md-3 pdg-fade-in">
                        <div class="pdg-arrow-container-row row">
                            <button
                                class="pdg-arrow-svg"
                                :aria-label="
                                    translations?.header?.rebranding?.['aria-down-arrow']
                                "
                                @click="autoScroll"
                            >
                                <i class="icon-arrow-down"></i>
                            </button>

                            <p
                                class="pdg-body-6 pdg-arrow-description col-10 col-sm-9 col-md-12"
                            >
                                {{ translations?.header?.rebranding?.['arrow-description'] }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    //HEADER SECTION CONTAINER
    .pdg-header-section-container {
        background: var(--bg-0);
        overflow: hidden;
        /* min-height: 100vh; */

        //MODAL VIDEO CONTAINER
        .pdg-modal-video-container {
            width: 100vw;
            height: 100vh;

            background: var(--bg-1);

            position: fixed;
            top: 0;
            left: 0;

            transition: $transition;

            z-index: 100;

            cursor: pointer;

            //MODAL CLOSE BTN
            .pdg-modal-close-btn {
                @include flex($align-items: center);

                gap: rem(32);

                color: var(--body-0);
                background: none;
                border: none;

                position: absolute;
                top: rem(24);
                right: rem(16);

                z-index: 1;

                @media screen and (min-width: map-get($breakpoints, md)) {
                    right: rem(map-get($gutter-container, md));
                }
            }

            //MODAL VIDEO ROW
            .pdg-modal-video-container-row {
                align-items: center;

                height: 100%;

                @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                    justify-content: center;
                }

                .pdg-modal-video {
                    @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                        width: 58.33333333%;
                        margin-left: 0;
                    }
                }
            }
        }

        //HEADER CONTAINER
        .pdg-header-container {
            height: 100vh;

            //HEADER CONTENT
            .pdg-header-content {
                @include flex($align-items: center);
                height: 48vh;

                color: var(--dark);

                padding-top: rem(88);
                padding-bottom: rem(16);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    height: 50vh;

                    padding-top: rem(172);
                    padding-bottom: rem(94);
                }

                @media screen and (max-height: map-get($horizontal-breakpoints, xs)),
                    (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                    height: 100vh;
                }

                //TITLE
                .pdg-title-h1 {
                    margin-bottom: vh(8);

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        margin-bottom: vh(32);
                    }
                }

                .pdg-title-h1,
                .pdg-subhead-2 {
                    &:deep(.pdg-line) .word {
                        opacity: 0;
                    }
                }
            }

            //VIDEO CONTAINER
            .pdg-video-container {
                /* opacity: 0; */

                height: 52dvh;

                color: var(--body-0);
                background: var(--bg-1);

                padding-top: rem(40);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    height: 50vh;

                    padding-top: rem(112);
                }

                @media screen and (max-height: map-get($horizontal-breakpoints, xs)) {
                    height: 100vh;

                    padding-top: rem(90);
                }

                @media screen and (max-height: map-get($horizontal-breakpoints, sm)) {
                    @include flex($align-items: center);
                }

                //VIDEO CONTAINER ROW
                .pdg-video-container-row {
                    @include flex(center, flex-end, column, nowrap);

                    height: 100%;
                    position: relative;

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        @include flex(null, center, row-reverse);
                    }

                    @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                        @include flex(null, flex-start, row-reverse);

                        /* height: auto; */
                    }
                }

                //MODAL CONTAINER
                .pdg-modal-container {
                    @media screen and (min-width: map-get($breakpoints, md)) {
                        height: 100%;
                    }

                    @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                        width: 66.66666667%;
                    }

                    .pdg-header-video-container {
                        max-height: 100%;

                        margin-right: rem(-16);

                        position: relative;

                        cursor: none;

                        @media (hover: hover) {
                            overflow: hidden;
                        }

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            margin-right: rem(-80);
                        }

                        @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) and (min-width: map-get($breakpoints, md)){
                            @include flex($align-items: center);
                            margin-right: 0;
                        }

                        .pdg-header-video-poster {
                            width: 100%;
                            height: 100%;

                            object-fit: cover;
                            object-position: top left;
                        }
                    }

                    .pdg-video-description {
                        height: 0;
                        margin: 0;
                        opacity: 0;

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            width: 100%;
                        }

                        @media screen and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                            //MIN-WIDTH XS
                            @media screen and (min-width: calc(map-get($horizontal-breakpoints, xs) + rem(0.1))) {
                                height: auto;

                                /* margin-left: rem(-8); */

                                /* transform: translate(-100%, -100%); */

                                opacity: 1;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }

                            //MAX-WIDTH XS
                            @media screen and (max-width: calc(map-get($horizontal-breakpoints, xs) - rem(0.1))) {
                                width: calc(100% - rem(24));
                            }
                        }
                    }
                }

                //ARROW CONTAINER
                .pdg-arrow-container {
                    @include flex(space-between, flex-start);

                    margin-top: rem(16);
                    margin-bottom: 10vh;

                    position: relative;

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        @include flex(center);

                        margin: 0;
                        margin-top: rem(64);
                    }

                    @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                        width: 33.33333333%;

                        margin-top: 0;
                    }

                    .pdg-arrow-container-row {
                        justify-content: flex-end;

                        /* @media screen and (min-width: map-get($horizontal-breakpoints, xs)) {
                            width: 100%;
                        } */

                        .pdg-arrow-svg {
                            width: fit-content;

                            color: var(--body-3);
                            background: none;

                            padding: 0;

                            position: absolute;
                            top: rem(12);
                            left: 0;

                            font-size: rem(40);

                            border: none;

                            outline: none;

                            transition: $transition;
                            transition-property: padding;

                            @media screen and (min-width: map-get($breakpoints, sm)) {
                                top: 0;
                            }

                            @media screen and (min-width: map-get($breakpoints, md)) {
                                top: rem(24);

                                left: rem(-64);

                                &:hover,
                                &:focus {
                                    padding-top: rem(20);
                                }
                            }

                            @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                                display: none;
                            }
                        }

                        .pdg-arrow-description {
                            margin: 0;

                            @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, sm)) and (orientation: landscape) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
