<template>
  <section class="pdg-container-principles pdg-container-height">
    <div class="pdg-container">
      <div class="pdg-section-info">
        <Chip
          :title="translations?.principles?.['info-tag']"
          class="dark-pink"
        />
        <div class="row">
          <div class="col-md-10 pdg-principles-title">
            <h1 class="pdg-title-principle-medium">{{ translations?.principles?.title }}</h1>
          </div>
          <div class="offset-2 col-10 offset-md-7 col-md-4 pdg-container-description pdg-body-2">
            <p class="pdg-description" v-html="translations?.principles?.description"></p>
          </div>
        </div>
      </div>
      <div class="pdg-section-slider">
        <div class="pdg-slider" data-slider>
          <div class="pdg-slider-buttons">
            <SliderControls
                    :prevAction="() => prevNextAdjective('prev')"
                    :nextAction="() => prevNextAdjective('next')"
                    class="dark-blue"
                />
            <h2 class="pdg-subhead-1 pdg-slider-subhead">
              {{ translations?.principles?.['buttons-subhead'] }}
            </h2>
          </div>

          <div class="pdg-slider-layout">
            <div class="pdg-slider-gradient"></div>
            <ul class="pdg-slider-track" data-slider-track>
              <li
                class="pdg-slider-slide"
                v-for="(title, index) in slidesPrinciples"
                :key="index"
                :style="colorBorderIndex(index)"
              >
                <div class="pdg-slide" :class="`pdg-slide-${slidesColor[index]}`">
                  <NuxtImg
                    class="pdg-img-slider"
                    :src="`/assets/img/principles/${title}.webp`"
                    :alt="translations?.principles?.slider?.[`slide-${index + 1}`]?.[`alt-${index + 1}`]"
                    loading="lazy"
                  />
                  <div class="pdg-overlay">
                    <i class="pdg-icon icon-asterisk-02"></i>
                    <h3 class="pdg-overlay-title pdg-title-h4">
                      {{ translations?.principles?.slider?.[`slide-${index + 1}`]?.title }}
                    </h3>
                    <p class="pdg-overlay-description pdg-body-3">
                      {{ translations?.principles?.slider?.[`slide-${index + 1}`]?.description }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <p class="pdg-contact-link pdg-button">
          <i class="icon-asterisk-01 pdg-asterisk-icon"></i>
          {{ translations?.principles?.['text-link'] }}
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import "../scss/includes.scss";
.pdg-container-principles {
  min-height: 100vh;
  color: var(--dark-blue);
  overflow: hidden;

  .pdg-container {
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */

    .pdg-section-info {
      display: flex;
      flex-direction: column;
      /* overflow: hidden; */
      flex: 1;
      align-items: flex-start;

      .pdg-principles-title {
        padding-bottom: rem(16);
        text-transform: uppercase;
      }

      .pdg-chip {
        margin-bottom: rem(16);
      }

      .pdg-title-principle-medium,
      .pdg-description {
        margin: 0;
      }
    }

    .pdg-section-slider {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pdg-slider {
        padding-top: rem(56);

        @media screen and (min-width: map-get($breakpoints, md)) {
          padding-top: rem(64);
        }

        .pdg-slider-buttons {
          @include flex($align-items: center);

          .pdg-slider-subhead {
            padding-left: rem(12);
            text-transform: uppercase;
            margin: 0;

            @media screen and (min-width: map-get($breakpoints, md)) {
              letter-spacing: rem(0.48);
            }
          }
        }

        .pdg-slider-layout {
          position: relative;
          @each $size, $value in $gutter-container {
            @media screen and (min-width: map-get($breakpoints, $size)) {
              margin: 0 rem(-$value);
            }
          }

          .pdg-slider-gradient {
            @each $size, $value in $gutter-container {
              @media screen and (min-width: map-get($breakpoints, $size)) {
                margin: 0 rem(-$value);
              }
            }

            @media screen and (min-width: map-get($breakpoints, md)) {
              position: absolute;
              top: 0;
              right: rem(80);
              bottom: 0;
              width: rem(236);
              background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0));
              z-index: 1;
              pointer-events: none;
            }
          }

          .pdg-slider-track {
            display: flex;
            padding-top: rem(12);
            list-style-type: none;
            padding-left: 0;
            overflow: auto;
            scroll-behavior: smooth;
            scrollbar-width: none;
            margin: 0;
            flex-wrap: nowrap;
            position: relative;

            &::-webkit-scrollbar {
              display: none;
            }

            @each $size, $value in $gutter-container {
              @media screen and (min-width: map-get($breakpoints, $size)) {
                padding: 0 rem($value);
                padding-top: rem(32);
              }
            }

            .pdg-slider-slide {
              width: 83.33333333%;
              flex: 0 0 auto;
              margin-right: rem(12);

              &:last-child {
                margin-right: 0;
              }

              @media screen and (min-width: map-get($breakpoints, sm)) {
                width: 41.66666667%;
                margin-right: rem(16);
              }

              @media screen and (min-width: map-get($breakpoints, md)) {
                width: 33.33333333%;
              }

              @media screen and (min-width: map-get($breakpoints, lg)) {
                width: 25%;
              }

              .pdg-slide {
                position: relative;

                .pdg-img-slider {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  min-height: rem(424);

                  @media screen and (min-width: map-get($breakpoints, sm)) {
                    min-height: rem(428);
                  }

                  @media screen and (min-width: map-get($breakpoints, lg)) {
                    min-height: rem(476);
                  }

                  @media screen and (min-width: map-get($breakpoints, xl)) {
                    min-height: rem(680);
                  }
                }

                .pdg-overlay {
                  @include flex(center, $flex-direction: column);
                  position: absolute;
                  height: 0;
                  padding: 0 rem(28);
                  overflow: hidden;
                  transition: 0.5s ease;
                  inset: 0;
                  top: inherit;

                  .pdg-icon {
                    @include typography(26, 400, 26);
                  }

                  .pdg-overlay-title {
                    padding-bottom: rem(12);
                    padding-top: rem(20);
                  }

                  .pdg-overlay-description {
                    width: 83.33333333%;
                  }
                }

                &.pdg-slide-green .pdg-overlay {
                  @include overlay-theme (
                    var(--green), 
                    var(--dark-blue), 
                    var(--dark-pink)
                  );
                }

                &.pdg-slide-dark-blue .pdg-overlay {
                  @include overlay-theme (
                    var(--dark-blue), 
                    var(--white), 
                    var(--green)
                  );
                }

                &.pdg-slide-white-smoke .pdg-overlay {
                  @include overlay-theme ( 
                    var(--white-smoke), 
                    var(--dark-blue), 
                    var(--dark-pink)
                  );
                }

                &.pdg-slide-pink-dark-blue .pdg-overlay {
                  @include overlay-theme (
                    var(--pink), 
                    var(--dark-blue), 
                    var(--blue)
                  );
                }

                &.pdg-slide-pink-blue .pdg-overlay {
                  @include overlay-theme (
                    var(--pink), 
                    var(--blue), 
                    var(--green)
                  );
                }

                &.pdg-slide-pink-blue-darkpink .pdg-overlay {
                  @include overlay-theme ( 
                    var(--pink), 
                    var(--blue), 
                    var(--dark-pink)
                  );
                }

                &.pdg-slide-dark-pink .pdg-overlay {
                  @include overlay-theme ( 
                    var(--dark-pink),
                    var(--white), 
                    var(--pink)
                  );
                }

                &.pdg-slide-dark-pink-blue .pdg-overlay {
                  @include overlay-theme ( 
                    var(--dark-pink), 
                    var(--white), 
                    var(--blue)
                  );
                }

                &.pdg-slide-blue .pdg-overlay {
                  @include overlay-theme ( 
                    var(--blue), 
                    var(--white), 
                    var(--green)
                  );
                }

                &:hover .pdg-overlay {
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .pdg-contact-link {
        @include flex($align-items: center);
        padding-top: rem(16);
        color: var(--blue);

          @media screen and (min-width: map-get($breakpoints, md)) {
            padding-top: rem(32);
          }

        .pdg-asterisk-icon {
          padding-right: rem(8);
          @include typography(16, 400, 16);
        }
      }
    }
  }
}
</style>

<script setup lang="ts">
  import { onMounted } from "vue";
  import gsap from "gsap";
  import { ScrollTrigger, Power1 } from "gsap/all";
  import { textAnimation } from "~/utils/animations.utils";

  const slidesColor = [
    "green",
    "dark-blue",
    "white-smoke",
    "pink-dark-blue",
    "dark-pink",
    "pink-blue",
    "blue",
    "green",
    "dark-pink-blue",
    "dark-blue",
    "green",
    "pink-blue-darkpink",
  ];

  const slidesPrinciples = [
    "agile_value_principles",
    "agile_change_principles",
    "agile_time_principles",
    "agile_collaboration_principles",
    "agile_motivation_principles",
    "agile_honest_principles",
    "agile_objectivity_principles",
    "agile_rhythm_principles",
    "agile_excellence_principles",
    "agile_essentiality_principles",
    "agile_organisation_principles",
    "agile_flexibility_principles",
  ];

  let track: HTMLElement;

  const { locale } = useI18n();
  const globalState = useGlobalState();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

  const prevNextAdjective = (direction: "prev" | "next") => {
      const currentScrollLeft = track.scrollLeft;
      const trackChild = track.firstElementChild as HTMLElement;
      const index = Math.ceil(currentScrollLeft / trackChild.offsetWidth) + (direction === 'prev' ? - 1 : 1) 

      if (trackChild) {
          const targetIndex = direction === 'prev' ? Math.max(0, index) : index;
          const targetOffset = trackChild.offsetWidth * targetIndex;

          track.scrollTo({
            left: targetOffset,
            behavior: "smooth",
          });
        }
      }

  const colorBorderIndex = (index: number) => ({
    border:
      index === 8 ? "1px solid var(--dark-pink)" : index === 9 ? "1px solid var(--blue)" : "none",
  });


  onMounted(() => {
    if (process.client) {
    const container = document.querySelector(".pdg-container-principles") as HTMLElement;
    
      if (container) {
        const slider = container.querySelector("[data-slider]") as HTMLElement;
        track = slider.querySelector("[data-slider-track]") as HTMLElement;
        const chip = container.querySelector(".pdg-chip") as HTMLElement;
        const chipWidth = chip.getBoundingClientRect().width;
        const mm = gsap.matchMedia(), breakPoint = 1024;
        mm.add(
          {
            isDesktop: `(min-width: ${breakPoint}px)`,
            isMobile: `(max-width: ${breakPoint - 1}px)`,
          }, () => {

            const startAnimation = gsap
            .timeline()
            .fromTo (
              ['.pdg-container-principles .pdg-section-info', 
              ".pdg-container-principles .pdg-principles-title", 
              ".pdg-container-principles .pdg-description", 
              ".pdg-container-principles .pdg-section-slider"],
              { opacity: 0 },
              { opacity: 1 },
              "entry"
            );

            //ANIMATION DELAY
            animationDelay(startAnimation, container);

            //CHIP ANIMATION
            chipAnimation({
              tl: startAnimation,
              container: ".pdg-container-principles",
              keyframe: "entry"
            });

            //TITLE ANIMATION
            textAnimation({
                split: true,
                tl: startAnimation,
                container: ".pdg-container-principles .pdg-principles-title",
                elements: [".pdg-title-principle-medium"],
                from: { yPercent: 100, opacity: 0 },
                to: { yPercent: 0, opacity: 1, duration: 0.6, ease: "power1" },
                keyframe: "entry",
              });

             //START ANIMATION 
             ScrollTrigger.create({
                  animation: startAnimation,
                  trigger: '.pdg-container-principles',
                  start: "top 50%",
                  end:"bottom 50%",
                  id: "PRINCIPLES",
                  preventOverlaps: true,
                  toggleActions: "play none play reverse",
                  // markers: {startColor: "red", endColor: "red", fontSize: '50'},
                  onEnter: () => {
                      gsap.to("body", {
                        backgroundColor:  '#fff',
                        overwrite: "auto"
                      })
                      gsap.to( ".content-menu", {
                        background: '#fff'
                      })
                  },
                  onEnterBack: () => {
                      gsap.to("body", {
                        backgroundColor: '#fff',
                        overwrite: "auto"
                      })
                      gsap.to( ".content-menu", {
                          background: '#fff'
                      })

                      gsap
                      .timeline()
                      .fromTo (
                        ['.pdg-container-principles .pdg-section-info', 
                        ".pdg-container-principles .pdg-principles-title", 
                        ".pdg-container-principles .pdg-description", 
                        ".pdg-container-principles .pdg-section-slider"],
                        { opacity: 0 },
                        { opacity: 1 },
                      );
                  },

                  onLeave: () => {
                    gsap
                    .timeline()
                    .fromTo (
                      ['.pdg-container-principles .pdg-section-info', 
                      ".pdg-container-principles .pdg-principles-title", 
                      ".pdg-container-principles .pdg-description", 
                      ".pdg-container-principles .pdg-section-slider"],
                      { opacity: 1 },
                      { opacity: 0 },
                    );
                  },
              });

              const exitAnimation = gsap
                .timeline()
                .fromTo (
                  chip,
                  {
                    duration: 0.5,
                    opacity: 1,
                    width: chipWidth,
                    ease: Power1.easeIn
                  },
                  {
                    width: 0,
                    opacity: 0
                  },
                  "exit"
                )
                .fromTo (
                ".pdg-container-principles .pdg-section-info .pdg-container-description",
                {
                  y: 0,
                  opacity: 1,
                  ease: "power1",
                  duration: 0.5,
                },
                {
                  y: -80,
                  opacity: 0,
                 
                },
                "exit"
                )
                .to (
                  ".pdg-container-principles .pdg-section-info",
                  {
                    flexGrow: 0,
                    height: 0
                  },
                  "exit"
                )

                textAnimation ({
                    tl: exitAnimation,
                    container: ".pdg-container-principles .pdg-principles-title",
                    elements: [".pdg-title-principle-medium"],
                    from: {
                      yPercent: 0,
                      opacity: 1
                    },
                    to: {
                      yPercent: 100,
                      opacity: 0,
                      duration: 0.6,
                      ease: "power1"
                    },
                    keyframe: "exit",
                  });

                  //EXIT ANIMATION MOBILE
                  
                    ScrollTrigger.create({
                      animation: exitAnimation,
                      trigger: '.pdg-container-principles',
                      start: "top+=50 top",
                      end: "center center",
                      id: 'PIN-PRINCIPLES',
                      pin: ".pdg-container-principles .pdg-container",
                      scrub: 2 ,
                      anticipatePin: 1,
                      pinSpacing: false,
                      invalidateOnRefresh: true,
                      preventOverlaps: true,
                      toggleActions: "play none play reverse",
                      // markers: { startColor: 'green', endColor: "green", fontSize: '30px'},
                    });
              }
          )}
      }
  });
</script>