<script setup lang="ts">
    defineProps(["src", "alt", "stickers", 'sizes']);

    let isMobile = ref<boolean>(true);

    const updateIsMobile = () =>
        (isMobile.value = window.matchMedia("(max-width: 1024px)").matches);

    onMounted(() => {
        updateIsMobile();
        window.addEventListener("resize", updateIsMobile);
    });
    onUnmounted(() => window.removeEventListener("resize", updateIsMobile));
</script>

<template>
    <div class="pdg-animated-image-container">
        <figure class="pdg-animated-image-figure">
            <NuxtImg
                :src="src"
                :alt="alt"
                class="pdg-animated-image"
                loading="lazy"
                format="webp"
                :sizes="sizes"
                densities="x1 x2"
            />
            <NuxtImg
                :src="src"
                :alt="alt"
                class="pdg-sticker"
                :class="[position, size]"
                v-for="({ src, alt, position, size }, index) in stickers"
                :key="index"
                :aria-hidden="isMobile"
                loading="lazy"
            />
        </figure>

        <div class="pdg-animated-image-overlay" />
    </div>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .pdg-animated-image-container {
        flex: 0 0 auto;

        position: relative;

        overflow: hidden;

        clip-path: content-box;

        @media screen and (min-width: map-get($breakpoints, md)) {
            clip-path: initial;
        }

        .pdg-animated-image-figure {
            height: 100%;

            margin: 0;

            .pdg-animated-image {
                width: 100%;
                height: 100%;

                object-fit: cover;

                transform: scale(1.1);

                opacity: 0;

                z-index: -1;
            }
        }

        .pdg-animated-image-overlay {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;

            transform: translateY(100%);

            z-index: 1;
        }

        .pdg-sticker {
            opacity: 0;
            visibility: hidden;

            width: rem(96);

            position: absolute;

            @media screen and (min-width: map-get($breakpoints, md)) {
                opacity: 1;
                visibility: visible;
            }

            //POSITION
            &.top-left {
                top: rem(-56);
                left: rem(-48);
            }

            &.top-right {
                top: rem(-48);
                right: rem(-48);
            }

            &.bottom-left {
                bottom: rem(-56);
                left: rem(-48);
            }

            &.bottom-right {
                bottom: rem(-56);
                right: rem(-48);
            }

            //SIZE
            &.small {
                width: rem(100);
            }

            &.big {
                width: rem(148);
            }
        }
    }
</style>
