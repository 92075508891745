<script setup lang="ts">
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";
    const globalState = useGlobalState();

    //GLOBAL VARIABLES
    let slidesContainer: HTMLElement;

    //MOVE FACTOR
    const moveFactor = 300;

    let horizontalSliderAnimation: GSAPAnimation;

    //ON TOUCH START EVENT
    const blockHorizontalSliderAutoScroll = () => horizontalSliderAnimation?.kill();
    const { locale } = useI18n();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    onMounted(() => {
        if (process.client) {

            //CONTAINER ASSIGNMENT
            const container = document.querySelector(".pdg-motto-container") as HTMLElement;

            //CONTAINER CHECK
            if (container) {


                //GSAP BREAKPOINTS
                const mm = gsap.matchMedia(),
                breakPoint = 1024;

                mm.add(
                    {
                        isDesktop: `(min-width: ${breakPoint}px)`,
                        isMobile: `(max-width: ${breakPoint - 0.1}px)`,
                    },
                    ({ conditions }) => {
                        const { isDesktop, isMobile } = conditions as gsap.Conditions;

                        //CONTAINER CHECK
                        if (container) {
                            const q = gsap.utils.selector(container);
                            //SLIDES CONTAINER
                            slidesContainer = container.querySelector(".pdg-motto-slides-container") as HTMLElement;

                            //SLIDES
                            const slides = Array.from(slidesContainer?.childNodes) as HTMLElement[];

                            //MOVE HANDLE
                            const moveHandle = ({ clientX, clientY }: MouseEvent) => {
                                const containerRect = container.getBoundingClientRect();
                                var relX = clientX - containerRect.left;
                                var relY = clientY - containerRect.top;
                                
                                slides?.forEach(
                                    (slide, i) => {
                                        const movement = i % 2 === 0 ? -100 : 100;
                                        const delay = i * 0.1; // Adjust the stagger delay as needed

                                        gsap.to(slide, {
                                            x: (relX - container.offsetWidth / 2) / container.offsetWidth * movement,
                                            y: (relY - container.offsetHeight / 2) / container.offsetHeight * movement,
                                            delay: delay
                                        });
                                    }
                                );
                            }

                            // //RESET IMAGES TRANSFORM
                            const resetImagesTransform = () =>
                                slides?.forEach(
                                    (slide) => (slide.style.transform = `translate(0, 0)`)
                                );

                            //AUTO HORIZONTAL SCROLL
                            const autoHorizontalScroll = (durationSeconds: number = 20) => {
                                const duration =
                                    durationSeconds *
                                    (1 -
                                        slidesContainer.scrollLeft /
                                            (slidesContainer.scrollWidth -
                                                slidesContainer.offsetWidth));

                                if (duration) {
                                    blockHorizontalSliderAutoScroll();

                                    horizontalSliderAnimation = gsap.to(slidesContainer, {
                                        scrollTo: {
                                            x: slidesContainer.scrollWidth,
                                        },
                                        duration,
                                    });
                                }
                            };

                            const animation = gsap.timeline({
                                onComplete: () => {
                                    if (isMobile) {
                                        autoHorizontalScroll()
                                        gsap.to( q('.pdg-motto-slides-container'), { overflowX: "auto"})
                                    }
                                }
                            });

                            //ANIMATION DELAY
                            animationDelay(animation, container);

                            //OPEN IMAGE ANIMATION
                            imageAnimation({
                                tl: animation,
                                container: ".pdg-motto-container",
                                keyframe: "entry+=0.3",
                            }).open();

                            //CONTENT DESCRIPTION
                            animation
                                .fromTo(
                                    ".pdg-motto-container .pdg-motto-content .pdg-body-2",
                                    {
                                        yPercent: 100,
                                        opacity: 0,
                                    },
                                    {
                                        yPercent: 0,
                                        opacity: 1,
                                    },
                                    "entry"
                                )
                                if (isDesktop) {
                                    //ANIMATED IMAGE OVERFLOW INITIAL
                                    animation.to(
                                        ".pdg-motto-container .pdg-animated-image-container",
                                        {
                                            overflow: "inherit",
                                        },
                                        "show-sticker"
                                    )
                                }

                                //STICKER
                                const stickers = q('.pdg-animated-image-container .pdg-sticker')
                                if (stickers.length > 0) {
                                    animation.fromTo(
                                        stickers,
                                        {
                                            scale: 0,
                                        },
                                        {
                                            scale: 1,
                                        },
                                        "show-sticker"
                                    );
                                }

                                //TEXT ANIMATION
                                textAnimation({
                                    tl: animation,
                                    split: true,
                                    container: ".pdg-motto-container .pdg-motto-content",
                                    elements: [".pdg-title-h1"],
                                    from: { yPercent: 100 },
                                    to: { yPercent: 0 },
                                    keyframe: "entry",
                                });

                                ScrollTrigger.create({
                                    animation,
                                    trigger: ".pdg-motto-container",
                                    start: "top 50%",
                                    end:"bottom 50%",
                                    id: "MOTTO",
                                    preventOverlaps: true,
                                    toggleActions: "play reverse play reverse",
                                    // markers: true,
                                    onUpdate({ getVelocity }) {
                                        // if (isDesktop) {
                                            gsap.fromTo(slides, {
                                                skewY: `${getVelocity() / 300}deg`
                                            }, {
                                                skewY: 0
                                            })
                                        // } 
                                    },
                                    // markers: {startColor: "red", endColor: "yellow", fontSize: '50'},
                                    onEnter: () => {
                                        setTimeout (() => {
                                            gsap.to("body", {
                                                backgroundColor: globalState.value.mode === 'normal' ? '#fff' : '#ee4266',
                                                overwrite: "auto"
                                            })
                                        }, 0)
                                    },
                                    onLeaveBack: () => 
                                    setTimeout (() => {
                                        gsap.to("body", {
                                            backgroundColor: globalState.value.mode === 'normal' ? '#0a2843' : '#c3f73a',
                                            overwrite: "auto"
                                        })
                                    }, 0)
                                });

                                //IS DESKTOP
                                if (isDesktop) {
                                    //MOVE HANDLE
                                    container.addEventListener("mousemove", moveHandle);

                                    return () =>
                                        container.removeEventListener("mousemove", moveHandle);
                                }

                                //IS MOBILE
                                else {
                                    resetImagesTransform();
                                }

                                //ON TOUCH START AND MOUSEOVER EVENT LISTENER
                                slidesContainer?.addEventListener("touchstart", () =>
                                    blockHorizontalSliderAutoScroll(), 
                                { passive: true }
                                );
                                slidesContainer?.addEventListener("mouseover", () =>
                                    blockHorizontalSliderAutoScroll()
                                );
                        }
                    }
                )
            }
        }
    });

    onUnmounted(() => {
        slidesContainer?.removeEventListener("mouseover", () =>
            blockHorizontalSliderAutoScroll()
        );
        slidesContainer?.removeEventListener("touchstart", () =>
            blockHorizontalSliderAutoScroll()
        );
    });
</script>

<template>
    <section class="pdg-motto-container pdg-structure-grid pdg-container">
        <!-- SLIDES CONTAINER -->
        <div class="pdg-motto-slides-container pdg-structure-grid pdg-scroll-x">
                <AnimatedImage
                    class="pdg-image-1 pdg-height-width-animated-image"
                    :src="`/assets/img/${globalState.mode}/motto/${globalState.mode === 'crazy' ? 'gaming-better-than-coding': 'coding'}.webp`"
                    :alt="translations?.motto?.['alt-images']?.['1']"
                    :stickers="
                        globalState.mode === 'crazy' && [
                            {
                                src: `/assets/img/crazy/motto/stickers/sticker-crazymode-colpa-del-backend.svg`,
                                alt: translations?.motto?.['alt-stickers']?.['1'],
                                position: 'bottom-right',
                                size: 'small',
                            },
                        ]
                    "
                    sizes="200px md:30vw"
                />
                <AnimatedImage
                    class="pdg-image-2 pdg-height-width-animated-image"
                    :src="`/assets/img/${globalState.mode}/motto/${globalState.mode === 'crazy' ? 'not-too-brilliant-idea': 'scrum-retrospective'}.webp`"
                    :alt="translations?.motto?.['alt-images']?.['2']"
                    :stickers="
                        globalState.mode === 'crazy' && [
                            {
                                src: `/assets/img/crazy/motto/stickers/sticker-crazymode-dev-new-designers.svg`,
                                alt: translations?.motto?.['alt-stickers']?.['2'],
                                position: 'top-left',
                                size: 'big',
                            },
                            {
                                src: `/assets/img/crazy/motto/stickers/sticker-crazymode-chaotic-organised.webp`,
                                alt: translations?.motto?.['alt-stickers']?.['3'],
                                position: 'top-right',
                                size: 'big',
                            },
                        ]
                    "
                    sizes="376px md:80vw"
                />
                <AnimatedImage
                    class="pdg-image-3 pdg-height-width-animated-image"
                    :class="`pdg-image-3-${globalState.mode}`"
                    :src="`/assets/img/${globalState.mode}/motto/${globalState.mode === 'crazy' ? 'acrobatic-selfie': 'development'}.webp`"
                    :alt="translations?.motto?.['alt-images']?.['3']"
                    sizes="200px md:40vw"
                />
                <AnimatedImage
                    class="pdg-image-4 pdg-height-width-animated-image"
                    :src="`/assets/img/${globalState.mode}/motto/${globalState.mode === 'crazy' ? 'fake-brainstorming': 'team-brainstorming'}.webp`"
                    :alt="translations?.motto?.['alt-images']?.['4']"
                    :stickers="
                        globalState.mode === 'crazy' && [
                            {
                                src: `/assets/img/crazy/motto/stickers/sticker-crazymode-stacco-bweekend.svg`,
                                alt: translations?.motto?.['alt-stickers']?.['4'],
                                position: 'bottom-left',
                                size: 'big',
                            },
                        ]
                    "
                    sizes="308px md:50vw"
                />
                <AnimatedImage
                    class="pdg-image-5 pdg-height-width-animated-image"
                    :src="`/assets/img/${globalState.mode}/motto/${globalState.mode === 'crazy' ? 'silence-in-the-hall-Shreck-has-started': 'company-presentation'}.webp`"
                    :alt="translations?.motto?.['alt-images']?.['5']"
                    :stickers="
                        globalState.mode === 'crazy' && [
                            {
                                src: `/assets/img/crazy/motto/stickers/sticker-crazymode-troppa-ansia.svg`,
                                alt: translations?.motto?.['alt-stickers']?.['5'],
                                position: 'top-left',
                                size: 'big',
                            },
                        ]
                    "
                    sizes="308px md:50vw"
                />
        </div>

            <!-- CONTENT -->
        <div class="pdg-motto-content">
            <div class="row">
                <h1
                    class="pdg-motto-title pdg-title-h1 col-12"
                    v-html="translations?.motto?.title"
                />
                <p
                    class="pdg-motto-description pdg-body-2 col-10 col-md-8 offset-2 offset-md-0"
                    v-html="translations?.motto?.description"
                />
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    //CONTAINER
    .pdg-motto-container {
       min-height: 100vh;

        color: var(--body-1);

        position: relative;

        overflow: hidden;

        padding-bottom: rem(80);

        padding-top: rem(80);

        @media screen and (min-width: map-get($breakpoints, md)) {
            padding-top: 0;
            padding-bottom: rem(140);
        }

        //SLIDES CONTAINER
        .pdg-motto-slides-container {
            @include flex();
            height: vh(260);
            transition: 0.1s;
            margin-top: rem(132);
            margin-bottom: rem(32);
            gap: rem(24);
            padding: 0;
            overflow-x: hidden;

            @media screen and (min-width: map-get($breakpoints, md)) {
                grid-column: 1 / -1;
                grid-row: 1 / -1;
                overflow: visible;
                height: 100%;
                margin: 0;
            }

                //ANIMATED IMAGE
                .pdg-animated-image-container {
                    &:nth-child(2n) {
                        height: calc(100% - vh(24));
                    }

                    //IMAGE 1
                    &.pdg-image-1 {
                        width: rem(200);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            grid-column: 1 / 4;
                            grid-row: 6 / 9;
                        }

                        @media screen and (min-width: map-get($breakpoints, md)) and (max-height: 1024px){
                            grid-row: 7 / 10;
                            padding-bottom: rem(16);
                        }

                        @media screen and (min-width: map-get($breakpoints, lg)) {
                            grid-column: 1 / 3;
                            grid-row: 9 / 12;
                        }

                        @each $size, $value in $gutter-container {
                            @if $size != xs and $size != sm {
                                @media screen and (min-width: map-get($breakpoints, $size)) {
                                    margin-left: rem(-$value);
                                }
                            }
                        }
                    }

                    //IMAGE 2
                    &.pdg-image-2 {
                        width: rem(376);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            grid-column: 1 / 7;
                            grid-row: 10 / -1;
                        }
                        
                        @media screen and (min-width: map-get($breakpoints, lg)) {
                            grid-column: 3 / 7;
                            grid-row: 10 / -1;
                        }
                    }

                    //IMAGE 3
                    &.pdg-image-3 {
                        width: rem(200);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            grid-column: 7 / 11;
                            grid-row: 5 / 8;
                            margin-top: rem(16);
                        }

                        @media screen and (min-width: map-get($breakpoints, lg)) {
                            grid-column: 8 / 10;
                            grid-row: 6 / 9;
                        }
                    }

                    //IMAGE 4
                    &.pdg-image-4 {
                        width: rem(308);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            grid-column: 8 / -1;
                            grid-row: 2 / 4;
                        }

                        @media screen and (min-width: map-get($breakpoints, lg)) {
                            grid-column: 9 / 12;
                            grid-row: 2 / 5;
                        }
                    }

                    //IMAGE 5
                    &.pdg-image-5 {
                        width: rem(308);

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            grid-column: 8 / -1;
                            grid-row: 9 / 12;

                                @each $size, $value in $gutter-container {
                                    @if $size != xs and $size != sm {
                                        @media screen and (min-width: map-get($breakpoints, $size)) {
                                            margin-left: rem($value);
                                        }
                                    }
                                }
                            }
                            
                            @media screen and (min-width: map-get($breakpoints, lg)) {
                                grid-column: 10 / -1;
                                grid-row: 10 / -1;
                            }
                    }

                    &.pdg-height-width-animated-image {
                        @media screen and (min-width: map-get($breakpoints, md)) {
                            width: 100%;
                            height: 100%;
                        }
                        transform: translate(0, 0);
                        transition: transform 0.1s;
                    }
                }

                //IMAGE OVERLAY
                &:deep(.pdg-animated-image-overlay) {
                    background: var(--green);
                }
        }

        //CONTENT
        .pdg-motto-content {
            @media screen and (min-width: map-get($breakpoints, md)) {
                grid-column: 1 / 8;
                grid-row: 3 / 5;
            }
            @media screen and (min-width: map-get($breakpoints, md)) and (max-height: map-get($horizontal-breakpoints, sm)) {
                grid-row: 2 / 5;
            }

            @media screen and (min-width: map-get($breakpoints, md)) and (max-height: map-get($horizontal-breakpoints, xs)) {
                grid-row: 1 / 5;
                padding-top: rem(32);
            }


            //TITLE
            .pdg-motto-title {
                margin-bottom: rem(16);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    margin-bottom: rem(24);
                }
            }

            //DESCRIPTION
            .pdg-motto-description {
                @media screen and (max-width: calc(map-get($breakpoints, md) - rem(1))) {
                    font-size: rem(18);
                    line-height: rem(20);
                }
            }
        }

        &,
        .pdg-structure-grid {
            @media screen and (min-width: map-get($breakpoints, md)) {
                display: grid;
                grid-template-columns: repeat(12, calc((100% - (rem(16) * 11)) / 12));
                grid-template-rows: repeat(12, calc(((100vh - (rem(16) * 11)) / 12)));
                gap: rem(16);
            }
        }
    }
</style>
