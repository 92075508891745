<template>
    <section class="pdg-container-section pdg-work-with-us-container pdg-container-height" id="work-with-us">
        <div class="pdg-container">
            <div class="row order-2 order-md-1">
                    <div class="col-md-10 offset-md-1">
                        <div class="pdg-container-lottie pdg-fade-in">
                            <client-only>
                                <Vue3Lottie :animationLink="`/assets/lottie/asterisk${globalState.mode === 'normal' ? '' : '-crazy'}.json`" />
                            </client-only>
                        </div>

                        <div class="pdg-title">
                            <h1 class="pdg-display">{{ translations?.['work-with-us']?.title }}</h1>
                        </div>
                    </div>

                 <div class="pdg-contact offset-2 col-10 col-md-4 offset-md-6 pdg-fade-in">
                    <p class="pdg-body-2 pdg-margin-p">
                        {{ translations?.['work-with-us']?.['contact-description'] }}
                    </p>
                    <ArrowLink
                        :text="translations?.['work-with-us']?.['contact-button']"
                        href="mailto: info@paradigma.me"
                        :external="true"
                        class="pdg-contact-link "
                    />
                </div>
            </div>

            <div class="pdg-slider-work-with-us pdg-fade-in order-1 order-md-2">
                <h2 class="pdg-subhead-2">{{ translations?.['work-with-us']?.['title-slider'] }}</h2>
                <div class="pdg-container-logos">
                    <div class="pdg-slider-work-with-us-gradient left"></div>
                    <div class="pdg-slider-container">
                        <NuxtImg
                            v-for="(item, index) in sliderLogos.reverse()"
                            class="pdg-logo"
                            :alt="translations?.['work-with-us']?.['alt-slider']?.[index]"
                            :src="`/assets/img/${globalState.mode}/work-with-us/${item}.svg`"
                            loading="lazy"
                        />
                    </div>
                    <div class="pdg-slider-work-with-us-gradient"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .pdg-container-section {
        color: var(--body-0);
        /* min-height: 100vh; */
        display: flex;
        padding: vh(56) 0;
        align-items: center;

        .pdg-container {
            display: flex;
            flex-direction: column;

            .pdg-container-lottie {
                width: 58.33333333%;

                @media screen and (min-width: map-get($breakpoints, md)) {
                    width: 25%;
                }
            }

            .pdg-title {
                padding-top: rem(32);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    padding-top: rem(24);
                }
            }

            .pdg-contact {
                padding-top: rem(24);

                @media screen and (min-width: map-get($breakpoints, md)) {
                    padding-top: rem(32);
                }

                .pdg-margin-p {
                    margin: 0;
                }

                .pdg-contact-link {
                    color: var(--body-7);
                }
            }

        .pdg-slider-work-with-us {
            padding-bottom: rem(100);
            position: relative;

            .pdg-slider-work-with-us-gradient {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: rem(64);
                background: linear-gradient(-90deg, var(--bg-4), rgba(#246399, 0));
                z-index: 1;
                pointer-events: none;

                @media screen and (min-width: map-get($breakpoints, sm)) {
                    width: rem(72);
                }

                @media screen and (min-width: map-get($breakpoints, md)) {
                    width: rem(236);
                }

                &.left {
                    right: initial;
                    left: 0;
                    background: linear-gradient(90deg, var(--bg-4), rgba(#246399, 0));
                }
            }

            @media screen and (min-width: map-get($breakpoints, md)) {
                margin-top: rem(32);
                padding-bottom: 0;
            }

            .pdg-subhead-2 {
                padding-bottom: rem(16);
                margin: 0;

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        padding-bottom: rem(24);
                    }
                }

                @keyframes slide {
                    from {
                        transform: translateX(0%);
                    }

                    to {
                        transform: translateX(-150%);
                    }
                }

                @-webkit-keyframes slide {
                    from {
                        -webkit-transform: translateX(0%);
                    }

                    to {
                        -webkit-transform: translateX(-150%);
                    }
                }

                .pdg-container-logos {
                    overflow-x: hidden;
                    white-space: nowrap;
                    position: relative;
                }

                .pdg-slider-container {
                    display: grid;
                    gap: 8px;
                    grid-template-columns: repeat(4, 1fr); /* number of visible images + width */
                    overflow: hidden;

                    @media screen and (min-width: map-get($breakpoints, sm)) {
                        gap: 12px;
                        grid-template-columns: repeat(6, 1fr); /* number of visible images + width */
                    }

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        gap: 24px;
                        grid-template-columns: repeat(8, 1fr); /* number of visible images + width */
                    }

                    @media screen and (min-width: map-get($breakpoints, lg)) {
                        grid-template-columns: repeat(10, 1fr); /* number of visible images + width */
                    }

                    $n: 26;
                    /* reference: https://verpex.com/blog/website-tips/how-to-create-a-css-only-infinite-scroll-animation */

                    @keyframes r {
                        #{calc(100% / ($n + ($n - 2) * 0.24))} {
                            transform: translate(-100%);
                            opacity: 0;
                        }
                        #{calc(100% / ($n + ($n - 2) * 0.24) + 0.1%)} {
                            transform: translate(calc(($n - 1 + ($n - 2) * 0.24) * 100%));
                            opacity: 0;
                        }
                        #{calc(100% / ($n + ($n - 2) * 0.24) + 0.2%)} {
                            opacity: 1;
                        }
                    }
                   

                    @for $i from 1 to ($n + 2) {
                        & > .pdg-logo:nth-child(#{$i}) {
                            animation-delay: #{calc((1 - $i) / $n) * 40s}
                        }
                    }

                    .pdg-logo {
                        height: rem(68);
                        width: auto;
                        grid-area: 1/1;
                        width: 100%;
                        animation: r 40s linear infinite;
                        animation-play-state: paused;
                        -webkit-animation-play-state: paused;

                        @media screen and (min-width: map-get($breakpoints, md)) {
                            height: rem(120);
                        }
                    }
                }
            }
        }
    }
</style>

<script setup lang="ts">
    import { onMounted } from "vue";
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";
    import { textAnimation } from "~/utils/animations.utils";

    const { locale } = useI18n();
    const globalState = useGlobalState();

    const sliderLogos = [
        'telepass-logo',
        'ibm-logo',
        'campari-logo',
        'poke-house-logo',
        'dxc-logo',
        'tinexta-group-logo',
        'enel-logo',
        'eni-logo',
        'cosmico-logo',
        'thomson-reuters-foundation-logo',
        'trenitalia-logo',
        'app-io-logo',
        'snai-logo',
        'snam-logo',
        'tim-logo',
        'manitese-logo',
        'nutribees-logo',
        'prestat-logo',
        'eclittica-logo',
        'motork-logo',
        'moak-logo',
        'nurale-logo',
        'numbers-logo',
        'eatsready-logo',
        'steve-jobs-academy-logo',
        'leonardo-logo'
    ]

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })

    onMounted(() => {
        if (process.client) {
            nextTick(() => {
                const container = document.querySelector(".pdg-work-with-us-container") as HTMLElement;

                if(container) {

                    const mm = gsap.matchMedia(), breakPoint = 1024;
                    mm.add(
                    {
                        isDesktop: `(min-width: ${breakPoint}px)`,
                        isMobile: `(max-width: ${breakPoint - 1}px)`,
                    }, () => {

                        const linearBlue = 'linear-gradient(-90deg, rgb(36, 99, 153), rgba(36, 99, 153, 0)';
                        const linearDarkBlue = 'linear-gradient(-90deg, rgb(10, 40, 67), rgba(10, 40, 67, 0))';
                        const linearGreen = 'linear-gradient(-90deg, rgb(195, 247, 58), rgba(195, 247, 58, 0))';
                        const linearWhite = 'linear-gradient(-90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0))';
                        const linearPink = 'linear-gradient(-90deg, rgb(255, 192, 213), rgba(255, 192, 213, 0)';
                        const linearBlueLeft = 'linear-gradient(90deg, rgb(36, 99, 153), rgba(36, 99, 153, 0)';
                        const linearDarkBlueLeft = 'linear-gradient(90deg, rgb(10, 40, 67), rgba(10, 40, 67, 0))';
                        const linearGreenLeft = 'linear-gradient(90deg, rgb(195, 247, 58), rgba(195, 247, 58, 0))';
                        const linearWhiteLeft = 'linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0))';
                        const linearPinkLeft = 'linear-gradient(90deg, rgb(255, 192, 213), rgba(255, 192, 213, 0)';
                        const logos = gsap.utils.toArray('.pdg-container-section .pdg-logo').reverse()

                        const startAnimation = gsap.timeline()
                            .to(
                                ".pdg-container-section .pdg-logo",
                                {
                                    animationPlayState: "running"
                                },
                            )
                            .fromTo(
                                ".pdg-container-lottie, .pdg-container-section .pdg-fade-in",
                                {
                                    y: 50,
                                    opacity: 0
                                },
                                {
                                    y: 0,
                                    opacity: 1,
                                    stagger: 0.2,
                                    ease: "power1",
                                    onComplete: function () {
                                        gsap.set(this.targets(), { clearProps: "all" })
                                    },
                                },
                                'entry'
                            )
                            .fromTo(
                                ".pdg-work-with-us-container .pdg-slider-container",
                                { opacity: 0 },
                                {
                                    opacity: 1,
                                    stagger: 0.1,
                                    ease: "power1",
                                },
                                'entry'
                            )

                        //ANIMATION DELAY
                        animationDelay(startAnimation, container);

                        //TITLE ANIMATION
                        textAnimation({
                            split: true,
                            tl: startAnimation,
                            container: ".pdg-work-with-us-container .pdg-title",
                            elements: [".pdg-display"],
                            from: { yPercent: 100, opacity: 0 },
                            to: { yPercent: 0, opacity: 1, duration: 0.4, ease: "power1" },
                            keyframe: 'entry'
                        });

                            ScrollTrigger.create({
                            animation: startAnimation,
                            trigger: ".pdg-work-with-us-container",
                            start: "top 50%",
                            end: "bottom 50%",
                            id: "WORKWITHUS",
                            toggleActions: "play reverse play reverse",
                            preventOverlaps: true,
                            // markers: { startColor: "black", endColor: "yellow", fontSize: '30', },
                            onEnter: () => {
                                setTimeout (() => {
                                    gsap.to(".pdg-slider-work-with-us-gradient", {
                                        background: globalState.value.mode === 'normal' ? linearBlue : linearGreen,
                                    });
                                    gsap.to(".pdg-slider-work-with-us-gradient.left", {
                                        background: globalState.value.mode === 'normal' ? linearBlueLeft : linearGreenLeft,
                                    });
        
                                    gsap.to("body", {
                                        backgroundColor: globalState.value.mode === 'normal' ? '#246399' : '#c3f73a',
                                    });
        
                                    gsap.to( ".content-menu", {
                                        background: globalState.value.mode === 'normal' ? '#246399' : '#c3f73a',
                                    })
                                }, 0)
                            },
                            onLeave: () => {
                                setTimeout (() => {
                                    gsap.to(".pdg-slider-work-with-us-gradient", { 
                                        background: linearDarkBlue,
                                    });
                                    gsap.to(".pdg-slider-work-with-us-gradient.left", { 
                                        background: linearDarkBlueLeft,
                                    });
                                }, 0)
                            },
                            onEnterBack: () => {
                                setTimeout (() => {
                                    gsap.to(".pdg-slider-work-with-us-gradient", {
                                        background: globalState.value.mode === 'normal' ? linearBlue : linearGreen,
                                    });
                                    gsap.to(".pdg-slider-work-with-us-gradient.left", {
                                        background: globalState.value.mode === 'normal' ? linearBlueLeft : linearGreenLeft,
                                    });
                                }, 0)
                            },
                            onLeaveBack: () => {
                                setTimeout (() => {
                                    gsap.to(".pdg-slider-work-with-us-gradient", {
                                        background: globalState.value.mode === 'normal' ? linearWhite : linearPink,
                                    });
                                    gsap.to(".pdg-slider-work-with-us-gradient.left", {
                                        background: globalState.value.mode === 'normal' ? linearWhiteLeft : linearPinkLeft,
                                    });
                                }, 0)
                            },
                        });
                    }
                )}
            })
        }
    });
</script>