<template>
    <svg
        width="151"
        height="153"
        viewBox="0 0 151 153"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="pdg-play-video-btn"
    >
        <ellipse
            opacity="0.3"
            cx="75.4004"
            cy="76.3383"
            rx="60.1294"
            ry="60.6522"
            fill="#FFC0D5"
            class="pdg-play-video-btn-bg"
        />
        <path
            d="M63.2473 56.663L97.6145 76.5043L63.2473 96.3456V56.663Z"
            stroke="#FFC0D5"
            stroke-width="1.07959"
        />

        <g clip-path="url(#clip0_2485_113344)" class="pdg-play-video-btn-text">
            <path
                d="M77.034 6.11419C77.4082 2.30441 80.0088 -0.325016 83.6555 0.0348508C86.7264 0.337138 88.329 2.12687 88.5401 4.59796L87.1918 4.46361C86.9998 2.55871 85.6084 1.38795 83.5403 1.18642C80.3255 0.869741 78.6605 3.57594 78.4014 6.24854C78.1375 8.94514 78.9772 11.8577 82.2976 12.1839C84.7975 12.4286 86.8223 10.7973 87.091 8.10065L82.8302 7.68321L82.9454 6.51724L88.5161 7.06424L87.8683 13.657L86.9711 13.5706L86.7983 11.2483H86.7599C85.8051 12.6734 84.3752 13.5514 82.1824 13.3355C78.411 12.966 76.6549 9.94317 77.034 6.11899V6.11419Z"
                fill="#FFC0D5"
            />
            <path
                d="M89.9562 11.4307L91.6692 5.20264L92.8016 5.51452L91.1558 11.5027C90.8439 12.6351 91.0982 13.7531 92.6288 14.1705C94.3178 14.6359 95.7573 13.7579 96.1123 12.4527L97.6526 6.84843L98.7849 7.16031L96.3474 16.037L95.2151 15.7251L95.5605 14.4728L95.5269 14.4632C94.8216 15.0534 93.6652 15.5236 92.0818 15.087C90.4984 14.6503 89.4188 13.4028 89.961 11.4355L89.9562 11.4307Z"
                fill="#FFC0D5"
            />
            <path
                d="M98.4634 14.2858C99.2551 12.4624 100.795 12.4432 103.151 13.0142C104.648 13.3693 105.589 13.6572 106.025 12.6496C106.4 11.7907 106.088 10.9654 104.73 10.3752C103.089 9.66507 102.259 9.99135 101.635 11.1957L100.493 10.7015C101.222 9.24763 102.719 8.38395 105.171 9.44436C106.726 10.1209 107.815 11.378 106.937 13.3981L105.104 17.6205C104.811 18.3019 104.773 18.6569 105.152 18.8249C105.219 18.8537 105.286 18.8825 105.392 18.9064L105.051 19.6838C104.855 19.6358 104.658 19.5926 104.461 19.5062C103.751 19.1991 103.458 18.7337 103.852 17.6445L103.818 17.6301C102.82 18.3403 101.582 18.4314 100.205 17.8316C98.5162 17.0975 97.8492 15.6868 98.4586 14.281L98.4634 14.2858ZM100.752 16.9536C102.475 17.7021 104.01 17.2846 104.672 15.7636L105.354 14.1898C104.955 14.4297 103.972 14.2378 102.892 13.9691C101.122 13.5564 100.1 13.6044 99.6294 14.6792C99.2071 15.658 99.5238 16.4258 100.747 16.9536H100.752Z"
                fill="#FFC0D5"
            />
            <path
                d="M107.11 20.9405L106.092 20.3551L110.694 12.3853L111.711 12.9706L110.771 14.5972L110.804 14.6164C111.817 13.8055 112.992 13.4601 114.115 14.1126C114.398 14.2758 114.604 14.4149 114.777 14.554L114.201 15.5521C114.062 15.4321 113.899 15.317 113.745 15.2258C112.306 14.3957 110.579 14.9379 109.686 16.4877L107.114 20.9405H107.11Z"
                fill="#FFC0D5"
            />
            <path
                d="M112.819 18.8439C114.609 16.416 117.133 16.0322 118.932 17.3613C120.228 18.3161 120.525 19.5205 120.381 20.6241L120.41 20.6432L123.524 16.416L124.469 17.1118L116.816 27.5047L115.871 26.809L116.759 25.6046L116.73 25.5854C115.775 26.0029 114.494 26.094 113.203 25.1392C111.399 23.8101 111.02 21.2862 112.814 18.8487L112.819 18.8439ZM113.932 24.3955C115.358 25.4463 117.224 24.9808 118.606 23.1047C119.978 21.243 119.863 19.3237 118.443 18.2777C116.903 17.1453 115.031 17.9179 113.812 19.5732C112.579 21.243 112.397 23.2583 113.937 24.3955H113.932Z"
                fill="#FFC0D5"
            />
            <path
                d="M119.422 26.6701C120.818 25.2594 122.262 25.7968 124.249 27.1739C125.515 28.0472 126.288 28.6518 127.06 27.8696C127.722 27.2027 127.727 26.3198 126.676 25.2834C125.405 24.0263 124.512 24.0359 123.495 24.9331L122.608 24.0599C123.812 22.9659 125.52 22.702 127.42 24.5781C128.629 25.768 129.186 27.337 127.641 28.9013L124.407 32.1784C123.889 32.7062 123.726 33.0229 124.018 33.3156C124.071 33.3684 124.119 33.4164 124.21 33.4788L123.615 34.0833C123.447 33.9682 123.284 33.8578 123.126 33.7043C122.574 33.1573 122.468 32.6247 123.226 31.7514L123.202 31.7274C122.017 32.0297 120.827 31.665 119.762 30.6142C118.452 29.3187 118.342 27.7641 119.417 26.6749L119.422 26.6701ZM120.592 29.9809C121.926 31.3004 123.51 31.4683 124.68 30.2879L125.885 29.0692C125.424 29.1508 124.58 28.6182 123.668 27.9704C122.171 26.9484 121.197 26.6221 120.372 27.457C119.623 28.2151 119.642 29.0452 120.592 29.9809Z"
                fill="#FFC0D5"
            />
            <path
                d="M134.124 32.5955L134.824 33.5359L127.435 39.0251L126.734 38.0846L134.124 32.5955ZM136.71 30.4507L137.612 31.6694L136.393 32.5715L135.491 31.3527L136.71 30.4507Z"
                fill="#FFC0D5"
            />
            <path
                d="M139.325 33.5073L139.977 34.4814L129.244 41.6499L128.591 40.6759L139.325 33.5073Z"
                fill="#FFC0D5"
            />
            <path
                d="M139.929 41.3525L140.491 42.5232L134.459 48.4778L134.469 48.4922L142.88 47.499L143.427 48.6409L133.668 49.5766L133.092 48.3722L139.934 41.3477L139.929 41.3525Z"
                fill="#FFC0D5"
            />
            <path
                d="M143.998 50.3727L144.411 51.4715L135.789 54.7007L135.376 53.6019L143.998 50.3727ZM147.079 49.0244L147.611 50.4447L146.191 50.9773L145.659 49.557L147.079 49.0244Z"
                fill="#FFC0D5"
            />
            <path
                d="M140.841 54.7778C143.754 54.0101 145.961 55.296 146.532 57.46C146.94 59.0146 146.417 60.1422 145.611 60.9147L145.62 60.9483L150.701 59.6096L150.999 60.742L138.519 64.0288L138.221 62.8964L139.67 62.5125L139.661 62.479C138.653 62.2007 137.602 61.4713 137.194 59.9167C136.623 57.7527 137.909 55.5455 140.841 54.773V54.7778ZM138.226 59.7968C138.677 61.5049 140.419 62.3158 142.674 61.7256C144.91 61.1355 146.028 59.5712 145.577 57.8631C145.088 56.011 143.149 55.44 141.158 55.9678C139.152 56.4956 137.741 57.9494 138.231 59.8015L138.226 59.7968Z"
                fill="#FFC0D5"
            />
            <path
                d="M143.345 64.7629C146.272 64.4414 148.345 65.9576 148.633 68.5967C148.921 71.2357 147.237 72.9247 144.689 73.2029C144.439 73.2317 144.185 73.2557 144.041 73.2557L143.249 66.0056C141.402 66.2263 139.771 67.4019 140.006 69.5563C140.212 71.442 141.584 72.037 142.357 72.1329L142.486 73.3181C140.769 73.0542 139.248 71.9458 138.998 69.6523C138.691 66.8549 140.567 65.07 143.35 64.7629H143.345ZM144.929 71.9266C146.56 71.7491 147.827 70.5399 147.621 68.6542C147.424 66.8789 145.984 65.7993 144.267 65.8953L144.924 71.9266H144.929Z"
                fill="#FFC0D5"
            />
            <path
                d="M144.315 74.6138C147.184 74.7194 149.109 76.4516 149.008 79.2105C148.907 81.9695 146.863 83.5577 143.994 83.4522C141.144 83.3466 139.219 81.6144 139.32 78.8555C139.421 76.0965 141.465 74.5083 144.315 74.6138ZM140.347 78.8938C140.27 81.0386 141.926 82.147 144.037 82.2238C146.167 82.3006 147.899 81.3169 147.976 79.1721C148.053 77.0273 146.398 75.919 144.267 75.8374C142.156 75.7606 140.424 76.7442 140.342 78.8938H140.347Z"
                fill="#FFC0D5"
            />
            <path
                d="M144.185 89.4301L138.557 106.584L137.444 106.219L143.072 89.0654L144.185 89.4301Z"
                fill="#FFC0D5"
            />
            <path
                d="M136.364 112.582C139.473 114.813 140.452 118.373 138.317 121.353C136.518 123.863 134.166 124.352 131.926 123.301L132.713 122.202C134.459 122.989 136.167 122.37 137.376 120.681C139.257 118.057 137.751 115.264 135.563 113.7C133.36 112.121 130.424 111.392 128.476 114.108C127.012 116.147 127.415 118.719 129.613 120.297L132.103 116.819L133.058 117.5L129.8 122.049L124.417 118.191L124.944 117.457L127.041 118.469L127.06 118.441C126.302 116.896 126.259 115.221 127.54 113.431C129.747 110.351 133.24 110.341 136.364 112.582Z"
                fill="#FFC0D5"
            />
            <path
                d="M125.309 121.104L129.858 125.695L129.023 126.521L124.652 122.106C123.827 121.272 122.728 120.936 121.6 122.054C120.358 123.287 120.401 124.971 121.351 125.931L125.439 130.062L124.604 130.887L118.126 124.347L118.961 123.522L119.878 124.443L119.902 124.419C119.739 123.512 119.911 122.274 121.077 121.118C122.243 119.962 123.865 119.65 125.305 121.099L125.309 121.104Z"
                fill="#FFC0D5"
            />
            <path
                d="M118.577 127.068C119.763 128.661 119.014 130.009 117.349 131.765C116.293 132.888 115.578 133.56 116.236 134.443C116.797 135.196 117.666 135.335 118.856 134.452C120.29 133.382 120.415 132.499 119.681 131.362L120.679 130.619C121.576 131.976 121.586 133.704 119.441 135.302C118.078 136.314 116.447 136.631 115.132 134.865L112.378 131.175C111.937 130.58 111.644 130.374 111.313 130.619C111.255 130.662 111.198 130.705 111.121 130.782L110.612 130.1C110.751 129.952 110.886 129.803 111.063 129.678C111.687 129.213 112.229 129.189 112.978 130.072L113.007 130.052C112.887 128.834 113.424 127.716 114.628 126.818C116.106 125.72 117.661 125.84 118.577 127.073V127.068ZM115.127 127.725C113.621 128.848 113.218 130.388 114.211 131.722L115.238 133.099C115.228 132.634 115.881 131.876 116.653 131.074C117.891 129.745 118.361 128.834 117.661 127.893C117.023 127.039 116.202 126.934 115.132 127.73L115.127 127.725Z"
                fill="#FFC0D5"
            />
            <path
                d="M108.511 131.257L109.523 130.667L114.158 138.622L113.146 139.212L112.2 137.59L112.172 137.61C112.373 138.891 112.085 140.081 110.963 140.738C110.684 140.901 110.454 141.011 110.248 141.093L109.667 140.095C109.84 140.037 110.022 139.951 110.176 139.86C111.61 139.025 111.999 137.254 111.097 135.709L108.506 131.266L108.511 131.257Z"
                fill="#FFC0D5"
            />
            <path
                d="M107.493 137.259C108.712 140.018 107.786 142.398 105.737 143.3C104.269 143.948 103.07 143.607 102.182 142.935L102.148 142.95L104.269 147.758L103.194 148.233L97.9834 136.424L99.0582 135.949L99.6628 137.321L99.6964 137.307C99.8115 136.271 100.368 135.119 101.836 134.467C103.885 133.564 106.265 134.486 107.489 137.264L107.493 137.259ZM102.124 135.465C100.507 136.18 99.9795 138.027 100.92 140.157C101.851 142.273 103.573 143.132 105.19 142.417C106.942 141.645 107.201 139.639 106.371 137.753C105.531 135.853 103.876 134.692 102.124 135.465Z"
                fill="#FFC0D5"
            />
            <path
                d="M97.4221 139.092C97.9499 141.006 96.7648 141.99 94.5768 143.026C93.1853 143.693 92.2784 144.053 92.5711 145.118C92.8206 146.02 93.5787 146.467 95.0086 146.073C96.7312 145.598 97.1726 144.821 96.8991 143.492L98.0987 143.161C98.4441 144.749 97.8252 146.366 95.2485 147.076C93.6123 147.527 91.9761 147.229 91.3908 145.109L90.1624 140.67C89.9657 139.955 89.769 139.653 89.3707 139.768C89.3035 139.787 89.2316 139.807 89.1308 139.855L88.9053 139.039C89.0876 138.952 89.2699 138.861 89.4763 138.804C90.2248 138.597 90.743 138.77 91.1173 139.864L91.1508 139.855C91.4819 138.679 92.3888 137.83 93.833 137.427C95.6084 136.937 97.0143 137.614 97.4221 139.092ZM93.9674 138.453C92.1585 138.952 91.2228 140.243 91.6642 141.841L92.1201 143.492C92.2784 143.055 93.1613 142.585 94.1737 142.119C95.8099 141.328 96.5776 140.651 96.2657 139.519C95.9826 138.492 95.2533 138.094 93.9674 138.449V138.453Z"
                fill="#FFC0D5"
            />
            <path
                d="M84.9418 148.87L83.7758 149.005L82.7202 139.859L83.8862 139.725L84.9418 148.87ZM85.508 152.186L84.0013 152.359L83.8286 150.852L85.3352 150.679L85.508 152.186Z"
                fill="#FFC0D5"
            />
            <path
                d="M81.5592 152.92L80.3884 152.997L79.5439 140.119L80.7147 140.042L81.5592 152.92Z"
                fill="#FFC0D5"
            />
            <path
                d="M74.4769 149.523L73.1813 149.427L71.0173 141.232H70.9981L67.6682 149.019L66.4062 148.928L70.4512 139.994L71.7851 140.094L74.4721 149.528L74.4769 149.523Z"
                fill="#FFC0D5"
            />
            <path
                d="M64.2617 151.903L62.7646 151.658L63.0094 150.161L64.5064 150.406L64.2617 151.903ZM64.6264 148.559L63.47 148.372L64.9574 139.289L66.1138 139.476L64.6264 148.559Z"
                fill="#FFC0D5"
            />
            <path
                d="M62.3802 143.631C61.5981 146.544 59.3861 147.815 57.2221 147.235C55.6723 146.817 54.9525 145.8 54.6838 144.72L54.6503 144.711L53.2828 149.783L52.1504 149.476L55.5043 137.01L56.6367 137.317L56.2481 138.766L56.2817 138.776C57.0206 138.041 58.1769 137.49 59.7316 137.907C61.8908 138.488 63.1671 140.7 62.3802 143.631ZM59.3237 138.867C57.6156 138.406 56.0465 139.519 55.442 141.765C54.8422 143.996 55.6435 145.743 57.3517 146.203C59.199 146.702 60.6624 145.301 61.195 143.31C61.7324 141.304 61.1758 139.361 59.3285 138.862L59.3237 138.867Z"
                fill="#FFC0D5"
            />
            <path
                d="M52.4721 140.839C51.2918 143.535 48.9455 144.576 46.5176 143.516C44.0849 142.456 43.4563 140.152 44.4831 137.801C44.5839 137.571 44.6846 137.341 44.7614 137.211L51.4453 140.128C52.1747 138.415 51.9635 136.415 49.9819 135.551C48.2449 134.793 47.0454 135.685 46.5799 136.309L45.4859 135.834C46.5703 134.476 48.2833 133.708 50.4041 134.634C52.9856 135.762 53.5997 138.276 52.4817 140.839H52.4721ZM45.4715 138.646C44.8142 140.152 45.2316 141.851 46.9686 142.609C48.6048 143.324 50.2602 142.609 51.0279 141.074L45.4715 138.646Z"
                fill="#FFC0D5"
            />
            <path
                d="M43.442 136.775C41.9162 139.208 39.4595 140.009 37.1179 138.541C34.7764 137.072 34.4261 134.51 35.9472 132.077C37.4634 129.659 39.9249 128.858 42.2616 130.326C44.6032 131.794 44.9534 134.357 43.442 136.775ZM41.7194 131.199C39.9009 130.057 38.1112 130.94 36.9884 132.73C35.856 134.534 35.8416 136.53 37.6649 137.667C39.4835 138.809 41.2732 137.926 42.4056 136.122C43.5284 134.333 43.5428 132.341 41.7194 131.199Z"
                fill="#FFC0D5"
            />
            <path
                d="M30.6691 129.246L18.6016 115.82L19.4748 115.038L31.5424 128.468L30.6691 129.25V129.246Z"
                fill="#FFC0D5"
            />
            <path
                d="M14.4944 110.955C11.0157 112.548 7.4362 111.622 5.91516 108.292C4.63404 105.485 5.37296 103.201 7.40261 101.776L7.964 103.009C6.41418 104.137 6.10229 105.927 6.96597 107.812C8.30947 110.749 11.4811 110.831 13.9282 109.713C16.3897 108.585 18.4865 106.397 17.0998 103.36C16.0586 101.081 13.6259 100.145 11.1644 101.272L12.9445 105.164L11.8793 105.653L9.55221 100.567L15.574 97.813L15.9482 98.6335L14.0289 99.9482L14.0433 99.9818C15.7611 100.092 17.2342 100.884 18.1506 102.885C19.7244 106.33 17.9971 109.367 14.4992 110.965L14.4944 110.955Z"
                fill="#FFC0D5"
            />
            <path
                d="M12.5896 97.1125L6.34233 98.7631L6.04484 97.6307L12.0474 96.0473C13.1846 95.7498 14.0195 94.9677 13.6164 93.4323C13.1702 91.7385 11.6875 90.9372 10.3776 91.2827L4.75892 92.7653L4.46143 91.6329L13.3621 89.2866L13.6596 90.419L12.4025 90.7501L12.4121 90.7837C13.2805 91.0955 14.2642 91.8633 14.6864 93.4515C15.1039 95.0397 14.5665 96.5991 12.5944 97.1221L12.5896 97.1125Z"
                fill="#FFC0D5"
            />
            <path
                d="M10.7855 88.3218C8.81341 88.5522 8.0217 87.2278 7.33076 84.9103C6.88453 83.4373 6.66381 82.4824 5.56982 82.6072C4.63896 82.7175 4.08237 83.3989 4.2551 84.8719C4.46143 86.6473 5.16677 87.1991 6.51986 87.1319L6.66381 88.3698C5.04201 88.4706 3.54017 87.6117 3.22829 84.9583C3.03156 83.2741 3.57376 81.7003 5.76174 81.4412L10.3344 80.9038C11.0686 80.8174 11.3996 80.6687 11.3469 80.256C11.3373 80.1841 11.3277 80.1121 11.2989 80.0065L12.1434 79.9058C12.201 80.0977 12.2633 80.2896 12.2873 80.5055C12.3785 81.2781 12.1242 81.7627 10.987 81.969V82.0074C12.105 82.5112 12.8055 83.538 12.9831 85.0255C13.199 86.8536 12.3113 88.1395 10.7903 88.3218H10.7855ZM11.9418 85.0063C11.7211 83.1398 10.5888 82.0218 8.94296 82.2137L7.2396 82.4152C7.64744 82.6407 7.97852 83.5812 8.28561 84.656C8.81821 86.3929 9.3748 87.2566 10.5408 87.1175C11.5964 86.9927 12.1002 86.3354 11.9466 85.0063H11.9418Z"
                fill="#FFC0D5"
            />
            <path
                d="M12.1964 77.5308L12.2156 78.7016L3.01259 78.8503L2.9934 77.6748L4.8695 77.646V77.6076C3.65076 77.1566 2.75349 76.3265 2.7343 75.0261C2.7295 74.6999 2.74389 74.4504 2.77748 74.2296L3.93385 74.2104C3.90026 74.3928 3.88587 74.5895 3.89067 74.7718C3.91946 76.432 5.27256 77.6364 7.05749 77.6076L12.2012 77.526L12.1964 77.5308Z"
                fill="#FFC0D5"
            />
            <path
                d="M7.49864 73.6252C4.49975 73.3085 2.89715 71.322 3.13226 69.0957C3.3002 67.4979 4.19267 66.6294 5.21948 66.1928V66.1592L-0.000976562 65.6074L0.123777 64.4414L12.959 65.8041L12.8343 66.9701L11.342 66.8117V66.8501C12.1817 67.4643 12.9014 68.5247 12.7335 70.1225C12.4984 72.3489 10.5119 73.9515 7.49384 73.6348L7.49864 73.6252ZM11.7307 69.8634C11.9178 68.1024 10.5743 66.7254 8.25675 66.4806C5.95841 66.2359 4.3558 67.3011 4.16867 69.0621C3.96715 70.967 5.57935 72.1905 7.62819 72.4064C9.69142 72.6272 11.5243 71.7683 11.7259 69.8634H11.7307Z"
                fill="#FFC0D5"
            />
            <path
                d="M10.9198 63.9663C8.9957 63.4721 8.73659 61.9511 8.92372 59.5424C9.03888 58.0069 9.17323 57.0377 8.10803 56.7642C7.20116 56.5291 6.43345 56.9705 6.06878 58.4004C5.62735 60.1326 6.07838 60.9003 7.3691 61.3225L7.06202 62.5269C5.51219 62.0374 4.4182 60.6939 5.08515 58.1077C5.50739 56.4667 6.5774 55.1904 8.7126 55.7374L13.1701 56.8794C13.8851 57.0617 14.2449 57.0425 14.3505 56.6395C14.3697 56.5675 14.3841 56.5003 14.3937 56.3899L15.2142 56.6011C15.1998 56.8026 15.1854 57.0041 15.1326 57.2152C14.9407 57.9686 14.5328 58.3284 13.3957 58.1125L13.3861 58.1461C14.2449 59.0194 14.528 60.2285 14.1586 61.6776C13.7027 63.4625 12.412 64.3406 10.9294 63.9615L10.9198 63.9663ZM13.1893 61.2889C13.6548 59.4704 13.0022 58.0165 11.3948 57.6039L9.73462 57.1769C10.0321 57.5319 10.0033 58.53 9.90256 59.6431C9.77301 61.4569 9.98413 62.4597 11.1213 62.7476C12.1529 63.0115 12.8583 62.5796 13.1893 61.2841V61.2889Z"
                fill="#FFC0D5"
            />
            <path
                d="M5.4834 47.1293L6.08318 45.7378L7.47466 46.3376L6.87488 47.7291L5.4834 47.1293ZM8.64063 48.2904L9.10125 47.2108L17.5605 50.8431L17.0999 51.9227L8.64063 48.2904Z"
                fill="#FFC0D5"
            />
            <path
                d="M6.8125 43.3388L7.33071 42.2832L18.9184 47.9643L18.4002 49.0199L6.8125 43.3388Z"
                fill="#FFC0D5"
            />
            <path
                d="M13.3045 38.8665L14.0338 37.7917L22.21 40.0181L22.2196 40.0037L17.1383 33.2286L17.8484 32.1826L23.5583 40.1525L22.805 41.256L13.2949 38.8665H13.3045Z"
                fill="#FFC0D5"
            />
            <path
                d="M16.3423 28.8382L17.3019 27.6626L18.4775 28.6222L17.5178 29.7978L16.3423 28.8382ZM19.0581 30.8246L19.8018 29.9178L26.932 35.7476L26.1882 36.6545L19.0581 30.8246Z"
                fill="#FFC0D5"
            />
            <path
                d="M24.4508 31.3387C22.3204 29.2083 22.3156 26.6556 23.899 25.0722C25.0362 23.9351 26.2742 23.8199 27.3442 24.127L27.3682 24.103L23.6543 20.3892L24.4844 19.5591L33.6154 28.6853L32.7853 29.5154L31.7249 28.455L31.7009 28.479C31.9696 29.4866 31.8689 30.7629 30.7317 31.9001C29.1483 33.4835 26.5956 33.4835 24.4508 31.3387ZM30.1079 31.07C31.3603 29.8177 31.1779 27.9032 29.5322 26.2574C27.896 24.626 25.9815 24.4437 24.7339 25.696C23.3808 27.0491 23.8655 29.0164 25.3241 30.4702C26.7924 31.9385 28.7596 32.4231 30.1127 31.07H30.1079Z"
                fill="#FFC0D5"
            />
            <path
                d="M31.8162 24.141C30.0648 21.7803 30.3239 19.2229 32.4543 17.6443C34.5847 16.0656 36.8975 16.6654 38.4233 18.7239C38.572 18.9254 38.7256 19.1317 38.7976 19.2565L32.9389 23.6036C34.0617 25.0863 35.8994 25.902 37.6412 24.6113C39.1622 23.4837 38.9847 21.9962 38.6776 21.2813L39.6324 20.5712C40.2706 22.1882 40.0835 24.0595 38.2266 25.4318C35.9666 27.1111 33.4763 26.3914 31.8114 24.1458L31.8162 24.141ZM37.1998 19.1605C36.2209 17.841 34.5367 17.3612 33.0157 18.4887C31.581 19.5539 31.3795 21.3437 32.3296 22.7735L37.1998 19.1605Z"
                fill="#FFC0D5"
            />
            <path
                d="M39.829 18.3302C38.4759 15.8016 39.0037 13.2633 41.4364 11.963C43.8739 10.6579 46.273 11.6271 47.6261 14.1606C48.9696 16.6749 48.4418 19.2083 46.0091 20.5134C43.5764 21.8185 41.1725 20.8493 39.829 18.335V18.3302ZM45.5245 19.6018C47.4198 18.5893 47.5398 16.5981 46.5465 14.7364C45.5437 12.8603 43.8164 11.8574 41.9259 12.8699C40.0306 13.8823 39.9106 15.8736 40.9134 17.7497C41.9115 19.6114 43.634 20.6142 45.5293 19.6018H45.5245Z"
                fill="#FFC0D5"
            />
            <path
                d="M52.7026 10.9842L70.3601 7.23682L70.6048 8.38359L52.9473 12.131L52.7026 10.9842Z"
                fill="#FFC0D5"
            />
        </g>
        <defs>
            <clipPath id="clip0_2485_113344">
                <rect width="151" height="152.999" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped lang="scss">
    @import "../../scss/includes.scss";

    .pdg-play-video-btn {
        width: rem(100);
        height: rem(100);
        position: absolute;
        top: rem(-50);
        right: rem(24);

        opacity: 0;
        visibility: hidden;

        @media screen and (min-width: map-get($breakpoints, md)) {
            width: rem(152);
            height: rem(152);

            right: rem(80);
            bottom: rem(80);

            &:hover {
                cursor: none;
            }
        }

        @media screen and (min-width: map-get($horizontal-breakpoints, xxs)) and (max-height: map-get($horizontal-breakpoints, xs)) and ((orientation: landscape)) {
            top: auto;
            right: rem(map-get($gutter-container, xs));

            @media screen and (max-width: map-get($horizontal-breakpoints, xs)) {
                width: rem(80);
                height: rem(80);
            }
        }

        .pdg-play-video-btn-bg {
            transform-origin: center;

            animation: play-video-btn-bg-scale infinite alternate 4s;

            @keyframes play-video-btn-bg-scale {
                from {
                    transform: scale(1);
                }

                to {
                    transform: scale(0.8);
                }
            }
        }

        .pdg-play-video-btn-text {
            transform-origin: center;

            animation: play-video-btn-text-rotation infinite 8s;

            @keyframes play-video-btn-text-rotation {
                0% {
                    transform: rotate(0);
                }

                50% {
                    transform: rotate(90deg);
                }

                100% {
                    transform: rotate(0);
                }
            }
        }
    }
</style>
